export const formatDashboardMetricsDate = (data) => {
    let start = (new Date(data.startDate)).toUTCString();
    start = new Date(start);
    start = start.getFullYear() + '-' + (start.getMonth()+1) + '-' + start.getDate()
    
    let end =  (new Date(data.endDate)).toUTCString();
    end = new Date(end);
    end = end.getFullYear() + '-' + (end.getMonth()+1) + '-' + end.getDate()

    return { start, end }
}

// a and b are javascript Date objects
export const dateDiffInDays = (a, b) => {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}