import { Navigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

export const ProtectedChildLayout = ({ children, allowedRoles }) => {
  const { role } = useAuth();

  if (allowedRoles.indexOf(role) === -1) {
    return <Navigate to="/unauthorized" />;
  }

  return <>{children}</>;
};
