import { theme } from '@hitachivantara/uikit-react-core';

export const radialChartData = {
  type: "radialBar",
  series: [94],
  options: {
    colors: ["black", "#ABE5A1"],
    chart: {
      offsetY: -10,
      type: "radialBar",
      toolbar: {
        show: true,
      },
    },
    title: {
      text: "SLO",
      align: "center",
    },
    responsive: [{
      breakpoint: 1000,
      options: {
        chart: {
          height: 300
        },
      }
    }],
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 225,
        hollow: {
          margin: 0,
          size: "80%",
          background: "#fff",
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: "front",
          dropShadow: {
            enabled: false,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.24,
          },
        },
        track: {
          background: "#fff",
          strokeWidth: "67%",
          margin: 0, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.35,
          },
        },

        dataLabels: {
          show: true,
          name: {
            offsetY: -10,
            show: true,
            color: "#888",
          },
          value: {
            formatter: function (val) {
              return parseInt(val);
            },
            color: "#111",
            fontSize: "36px",
            show: true,
          },
        },
      },
    },
    stroke: {
      lineCap: "round",
    },
    labels: ["SLO"],
  },
};

export const mttdData = {
  series: [{
    name: 'MTTD',
    data: [4, 3, 10, 9, 29, 19, 22, 9, 12, 7, 19, 5, 13, 9, 17, 2, 7, 5]
  }],
  options: {
    colors: ['#77B6EA'],
    forecastDataPoints: {
      count: 7
    },
    stroke: {
      width: 5,
      curve: 'smooth'
    },
    xaxis: {
      type: 'datetime',
      categories: ['1/11/2000', '2/11/2000', '3/11/2000', '4/11/2000', '5/11/2000', '6/11/2000', '7/11/2000', '8/11/2000', '9/11/2000', '10/11/2000', '11/11/2000', '12/11/2000', '1/11/2001', '2/11/2001', '3/11/2001', '4/11/2001', '5/11/2001', '6/11/2001'],
      tickAmount: 10,
      labels: {
        formatter: function (value, timestamp, opts) {
          return opts.dateFormatter(new Date(timestamp), 'dd MMM')
        }
      }
    },
    title: {
      text: 'Mean Time To Detect (MTTD)',
      align: 'center',
      style: {
        fontSize: "16px",
        color: '#666'
      }
    },
    // fill: {
    //   type: 'gradient',
    //   gradient: {
    //     shade: 'dark',
    //     gradientToColors: ['#FDD835'],
    //     shadeIntensity: 1,
    //     type: 'horizontal',
    //     opacityFrom: 1,
    //     opacityTo: 1,
    //     stops: [0, 100, 100, 100]
    //   },
    // },
    yaxis: {
      min: -10,
      max: 40
    }
  }
};

export const slaData = {
  series: [
    {
      name: 'Time to Acknowledge',
      data: [28, 29, 33, 36, 32, 32, 33]
    },
    {
      name: 'Time to Resolve',
      data: [12, 11, 14, 18, 17, 13, 13]
    }
  ],
  options: {
    chart: {
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
      toolbar: {
        show: false
      },
    },
    colors: ['#77B6EA', '#545454'],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth'
    },
    title: {
      text: 'SLA',
      align: 'center'
    },
    grid: {
      borderColor: '#e7e7e7',
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      },
    },
    markers: {
      size: 1
    },
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
      title: {
        text: 'Month'
      }
    },
    yaxis: {
      title: {
        text: 'Service Level Agreement'
      },
      min: 5,
      max: 40
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      offsetY: -25,
      offsetX: -5
    }
  }
};

export const errorBudgetData = {

  series: [15, 85],
  options: {
    title: {
      text: "Error Budget",
      align: "center",
    },
    dataLabels: {
      enabled: true
    },
    legend: {
      show: false
    },
    chart: {
      type: 'pie',
    },
    labels: ['Error Budget', 'Error Budget Left %'],
    colors: ['#545454', '#1f7bc7'],
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
      }
    }]
  },


};

export const changeFailureRateData = {
  series: [67],
  options: {
    chart: {
      toolbar: {
        show: true,
      }
    },
    color: ['#1f7bc7'],
    title: {
      text: 'Change Failure Rate',
      align: 'center'
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        dataLabels: {
          name: {
            fontSize: '16px',
            color: undefined,
            offsetY: 120
          },
          value: {
            offsetY: 76,
            fontSize: '22px',
            color: undefined,
            formatter: function (val) {
              return val + "%";
            }
          }
        }
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        shadeIntensity: 0.15,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 65, 91]
      },
    },
    stroke: {
      dashArray: 4
    },
    labels: ['Change Failure Rate'],
  }
};

export const areaChartTimelineData = {
  series: [
    {
      name: "series1",
      data: [
        [1327359600000, 30.95],
        [1327446000000, 31.34],
        [1327532400000, 31.18],
        [1327618800000, 31.05],
        [1327878000000, 31.0],
        [1327964400000, 30.95],
        [1328050800000, 31.24],
        [1328137200000, 31.29],
        [1328223600000, 31.85],
        [1328482800000, 31.86],
        [1328569200000, 32.28],
        [1328655600000, 32.1],
        [1328742000000, 32.65],
        [1328828400000, 32.21],
        [1329087600000, 32.35],
        [1329174000000, 32.44],
        [1329260400000, 32.46],
        [1329346800000, 32.86],
        [1329433200000, 32.75],
        [1329778800000, 32.54],
        [1329865200000, 32.33],
        [1329951600000, 32.97],
        [1330038000000, 33.41],
        [1330297200000, 33.27],
        [1330383600000, 33.27],
        [1330470000000, 32.89],
        [1330556400000, 33.1],
        [1330642800000, 33.73],
        [1330902000000, 33.22],
        [1330988400000, 31.99],
        [1331074800000, 32.41],
        [1331161200000, 33.05],
        [1331247600000, 33.64],
        [1331506800000, 33.56],
        [1331593200000, 34.22],
        [1331679600000, 33.77],
        [1331766000000, 34.17],
        [1331852400000, 33.82],
        [1332111600000, 34.51],
        [1332198000000, 33.16],
        [1332284400000, 33.56],
        [1332370800000, 33.71],
        [1332457200000, 33.81],
        [1332712800000, 34.4],
        [1332799200000, 34.63],
        [1332885600000, 34.46],
        [1332972000000, 34.48],
        [1333058400000, 34.31],
        [1333317600000, 34.7],
        [1333404000000, 34.31],
        [1333490400000, 33.46],
        [1333576800000, 33.59],
        [1333922400000, 33.22],
        [1334008800000, 32.61],
        [1334095200000, 33.01],
        [1334181600000, 33.55],
        [1334268000000, 33.18],
        [1334527200000, 32.84],
        [1334613600000, 33.84],
        [1334700000000, 33.39],
        [1334786400000, 32.91],
        [1334872800000, 33.06],
        [1335132000000, 32.62],
        [1335218400000, 32.4],
        [1335304800000, 33.13],
        [1335391200000, 33.26],
        [1335477600000, 33.58],
        [1335736800000, 33.55],
        [1335823200000, 33.77],
        [1335909600000, 33.76],
        [1335996000000, 33.32],
        [1336082400000, 32.61],
        [1336341600000, 32.52],
        [1336428000000, 32.67],
        [1336514400000, 32.52],
        [1336600800000, 31.92],
        [1336687200000, 32.2],
        [1336946400000, 32.23],
        [1337032800000, 32.33],
        [1337119200000, 32.36],
        [1337205600000, 32.01],
        [1337292000000, 31.31],
        [1337551200000, 32.01],
        [1337637600000, 32.01],
        [1337724000000, 32.18],
        [1337810400000, 31.54],
        [1337896800000, 31.6],
        [1338242400000, 32.05],
        [1338328800000, 31.29],
        [1338415200000, 31.05],
        [1338501600000, 29.82],
        [1338760800000, 30.31],
        [1338847200000, 30.7],
        [1338933600000, 31.69],
        [1339020000000, 31.32],
        [1339106400000, 31.65],
        [1339365600000, 31.13],
        [1339452000000, 31.77],
        [1339538400000, 31.79],
        [1339624800000, 31.67],
        [1339711200000, 32.39],
        [1339970400000, 32.63],
        [1340056800000, 32.89],
        [1340143200000, 31.99],
        [1340229600000, 31.23],
        [1340316000000, 31.57],
        [1340575200000, 30.84],
        [1340661600000, 31.07],
        [1340748000000, 31.41],
        [1340834400000, 31.17],
        [1340920800000, 32.37],
        [1341180000000, 32.19],
        [1341266400000, 32.51],
        [1341439200000, 32.53],
        [1341525600000, 31.37],
        [1341784800000, 30.43],
        [1341871200000, 30.44],
        [1341957600000, 30.2],
        [1342044000000, 30.14],
        [1342130400000, 30.65],
        [1342389600000, 30.4],
        [1342476000000, 30.65],
        [1342562400000, 31.43],
        [1342648800000, 31.89],
        [1342735200000, 31.38],
        [1342994400000, 30.64],
        [1343080800000, 30.02],
        [1343167200000, 30.33],
        [1343253600000, 30.95],
        [1343340000000, 31.89],
        [1343599200000, 31.01],
        [1343685600000, 30.88],
        [1343772000000, 30.69],
        [1343858400000, 30.58],
        [1343944800000, 32.02],
        [1344204000000, 32.14],
        [1344290400000, 32.37],
        [1344376800000, 32.51],
        [1344463200000, 32.65],
        [1344549600000, 32.64],
        [1344808800000, 32.27],
        [1344895200000, 32.1],
        [1344981600000, 32.91],
        [1345068000000, 33.65],
        [1345154400000, 33.8],
        [1345413600000, 33.92],
        [1345500000000, 33.75],
        [1345586400000, 33.84],
        [1345672800000, 33.5],
        [1345759200000, 32.26],
        [1346018400000, 32.32],
        [1346104800000, 32.06],
        [1346191200000, 31.96],
        [1346277600000, 31.46],
        [1346364000000, 31.27],
        [1346709600000, 31.43],
        [1346796000000, 32.26],
        [1346882400000, 32.79],
        [1346968800000, 32.46],
        [1347228000000, 32.13],
        [1347314400000, 32.43],
        [1347400800000, 32.42],
        [1347487200000, 32.81],
        [1347573600000, 33.34],
        [1347832800000, 33.41],
        [1347919200000, 32.57],
        [1348005600000, 33.12],
        [1348092000000, 34.53],
        [1348178400000, 33.83],
        [1348437600000, 33.41],
        [1348524000000, 32.9],
        [1348610400000, 32.53],
        [1348696800000, 32.8],
        [1348783200000, 32.44],
        [1349042400000, 32.62],
        [1349128800000, 32.57],
        [1349215200000, 32.6],
        [1349301600000, 32.68],
        [1349388000000, 32.47],
        [1349647200000, 32.23],
        [1349733600000, 31.68],
        [1349820000000, 31.51],
        [1349906400000, 31.78],
        [1349992800000, 31.94],
        [1350252000000, 32.33],
        [1350338400000, 33.24],
        [1350424800000, 33.44],
        [1350511200000, 33.48],
        [1350597600000, 33.24],
        [1350856800000, 33.49],
        [1350943200000, 33.31],
        [1351029600000, 33.36],
        [1351116000000, 33.4],
        [1351202400000, 34.01],
        [1351638000000, 34.02],
        [1351724400000, 34.36],
        [1351810800000, 34.39],
        [1352070000000, 34.24],
        [1352156400000, 34.39],
        [1352242800000, 33.47],
        [1352329200000, 32.98],
        [1352415600000, 32.9],
        [1352674800000, 32.7],
        [1352761200000, 32.54],
        [1352847600000, 32.23],
        [1352934000000, 32.64],
        [1353020400000, 32.65],
        [1353279600000, 32.92],
        [1353366000000, 32.64],
        [1353452400000, 32.84],
        [1353625200000, 33.4],
        [1353884400000, 33.3],
        [1353970800000, 33.18],
        [1354057200000, 33.88],
        [1354143600000, 34.09],
        [1354230000000, 34.61],
        [1354489200000, 34.7],
        [1354575600000, 35.3],
        [1354662000000, 35.4],
        [1354748400000, 35.14],
        [1354834800000, 35.48],
        [1355094000000, 35.75],
        [1355180400000, 35.54],
        [1355266800000, 35.96],
        [1355353200000, 35.53],
        [1355439600000, 37.56],
        [1355698800000, 37.42],
        [1355785200000, 37.49],
        [1355871600000, 38.09],
        [1355958000000, 37.87],
        [1356044400000, 37.71],
        [1356303600000, 37.53],
        [1356476400000, 37.55],
        [1356562800000, 37.3],
        [1356649200000, 36.9],
        [1356908400000, 37.68],
        [1357081200000, 38.34],
        [1357167600000, 37.75],
        [1357254000000, 38.13],
        [1357513200000, 37.94],
        [1357599600000, 38.14],
        [1357686000000, 38.66],
        [1357772400000, 38.62],
        [1357858800000, 38.09],
        [1358118000000, 38.16],
        [1358204400000, 38.15],
        [1358290800000, 37.88],
        [1358377200000, 37.73],
        [1358463600000, 37.98],
        [1358809200000, 37.95],
        [1358895600000, 38.25],
        [1358982000000, 38.1],
        [1359068400000, 38.32],
        [1359327600000, 38.24],
        [1359414000000, 38.52],
        [1359500400000, 37.94],
        [1359586800000, 37.83],
        [1359673200000, 38.34],
        [1359932400000, 38.1],
        [1360018800000, 38.51],
        [1360105200000, 38.4],
        [1360191600000, 38.07],
        [1360278000000, 39.12],
        [1360537200000, 38.64],
        [1360623600000, 38.89],
        [1360710000000, 38.81],
        [1360796400000, 38.61],
        [1360882800000, 38.63],
        [1361228400000, 38.99],
        [1361314800000, 38.77],
        [1361401200000, 38.34],
        [1361487600000, 38.55],
        [1361746800000, 38.11],
        [1361833200000, 38.59],
        [1361919600000, 39.6],
      ],
    },
    {
      name: "series2",
      data: [
        [1327359600000, 50.95],
        [1327446000000, 51.34],
        [1327532400000, 51.18],
        [1327618800000, 51.05],
        [1327878000000, 51],
        [1327964400000, 50.95],
        [1328050800000, 51.24],
        [1328137200000, 51.29],
        [1328223600000, 51.85],
        [1328482800000, 51.86],
        [1328569200000, 52.28],
        [1328655600000, 52.1],
        [1328742000000, 52.65],
        [1328828400000, 52.21],
        [1329087600000, 52.35],
        [1329174000000, 52.44],
        [1329260400000, 52.46],
        [1329346800000, 52.86],
        [1329433200000, 52.75],
        [1329778800000, 52.54],
        [1329865200000, 52.33],
        [1329951600000, 52.97],
        [1330038000000, 53.41],
        [1330297200000, 53.27],
        [1330383600000, 53.27],
        [1330470000000, 52.89],
        [1330556400000, 53.1],
        [1330642800000, 53.73],
        [1330902000000, 53.22],
        [1330988400000, 51.99],
        [1331074800000, 52.41],
        [1331161200000, 53.05],
        [1331247600000, 53.64],
        [1331506800000, 53.56],
        [1331593200000, 54.22],
        [1331679600000, 53.77],
        [1331766000000, 54.17],
        [1331852400000, 53.82],
        [1332111600000, 54.51],
        [1332198000000, 53.16],
        [1332284400000, 53.56],
        [1332370800000, 53.71],
        [1332457200000, 53.81],
        [1332712800000, 54.4],
        [1332799200000, 54.63],
        [1332885600000, 54.46],
        [1332972000000, 54.48],
        [1333058400000, 54.31],
        [1333317600000, 54.7],
        [1333404000000, 54.31],
        [1333490400000, 53.46],
        [1333576800000, 53.59],
        [1333922400000, 53.22],
        [1334008800000, 52.61],
        [1334095200000, 53.01],
        [1334181600000, 53.55],
        [1334268000000, 53.18],
        [1334527200000, 52.84],
        [1334613600000, 53.84],
        [1334700000000, 53.39],
        [1334786400000, 52.91],
        [1334872800000, 53.06],
        [1335132000000, 52.62],
        [1335218400000, 52.4],
        [1335304800000, 53.13],
        [1335391200000, 53.26],
        [1335477600000, 53.58],
        [1335736800000, 53.55],
        [1335823200000, 53.77],
        [1335909600000, 53.76],
        [1335996000000, 53.32],
        [1336082400000, 52.61],
        [1336341600000, 52.52],
        [1336428000000, 52.67],
        [1336514400000, 52.52],
        [1336600800000, 51.92],
        [1336687200000, 52.2],
        [1336946400000, 52.23],
        [1337032800000, 52.33],
        [1337119200000, 52.36],
        [1337205600000, 52.01],
        [1337292000000, 51.31],
        [1337551200000, 52.01],
        [1337637600000, 52.01],
        [1337724000000, 52.18],
        [1337810400000, 51.54],
        [1337896800000, 51.6],
        [1338242400000, 52.05],
        [1338328800000, 51.29],
        [1338415200000, 51.05],
        [1338501600000, 49.82],
        [1338760800000, 50.31],
        [1338847200000, 50.7],
        [1338933600000, 51.69],
        [1339020000000, 51.32],
        [1339106400000, 51.65],
        [1339365600000, 51.13],
        [1339452000000, 51.77],
        [1339538400000, 51.79],
        [1339624800000, 51.67],
        [1339711200000, 52.39],
        [1339970400000, 52.63],
        [1340056800000, 52.89],
        [1340143200000, 51.99],
        [1340229600000, 51.23],
        [1340316000000, 51.57],
        [1340575200000, 50.84],
        [1340661600000, 51.07],
        [1340748000000, 51.41],
        [1340834400000, 51.17],
        [1340920800000, 52.37],
        [1341180000000, 52.19],
        [1341266400000, 52.51],
        [1341439200000, 52.53],
        [1341525600000, 51.37],
        [1341784800000, 50.43],
        [1341871200000, 50.44],
        [1341957600000, 50.2],
        [1342044000000, 50.14],
        [1342130400000, 50.65],
        [1342389600000, 50.4],
        [1342476000000, 50.65],
        [1342562400000, 51.43],
        [1342648800000, 51.89],
        [1342735200000, 51.38],
        [1342994400000, 50.64],
        [1343080800000, 50.02],
        [1343167200000, 50.33],
        [1343253600000, 50.95],
        [1343340000000, 51.89],
        [1343599200000, 51.01],
        [1343685600000, 50.88],
        [1343772000000, 50.69],
        [1343858400000, 50.58],
        [1343944800000, 52.02],
        [1344204000000, 52.14],
        [1344290400000, 52.37],
        [1344376800000, 52.51],
        [1344463200000, 52.65],
        [1344549600000, 52.64],
        [1344808800000, 52.27],
        [1344895200000, 52.1],
        [1344981600000, 52.91],
        [1345068000000, 53.65],
        [1345154400000, 53.8],
        [1345413600000, 53.92],
        [1345500000000, 53.75],
        [1345586400000, 53.84],
        [1345672800000, 53.5],
        [1345759200000, 52.26],
        [1346018400000, 52.32],
        [1346104800000, 52.06],
        [1346191200000, 51.96],
        [1346277600000, 51.46],
        [1346364000000, 51.27],
        [1346709600000, 51.43],
        [1346796000000, 52.26],
        [1346882400000, 52.79],
        [1346968800000, 52.46],
        [1347228000000, 52.13],
        [1347314400000, 52.43],
        [1347400800000, 52.42],
        [1347487200000, 52.81],
        [1347573600000, 53.34],
        [1347832800000, 53.41],
        [1347919200000, 52.57],
        [1348005600000, 53.12],
        [1348092000000, 54.53],
        [1348178400000, 53.83],
        [1348437600000, 53.41],
        [1348524000000, 52.9],
        [1348610400000, 52.53],
        [1348696800000, 52.8],
        [1348783200000, 52.44],
        [1349042400000, 52.62],
        [1349128800000, 52.57],
        [1349215200000, 52.6],
        [1349301600000, 52.68],
        [1349388000000, 52.47],
        [1349647200000, 52.23],
        [1349733600000, 51.68],
        [1349820000000, 51.51],
        [1349906400000, 51.78],
        [1349992800000, 51.94],
        [1350252000000, 52.33],
        [1350338400000, 53.24],
        [1350424800000, 53.44],
        [1350511200000, 53.48],
        [1350597600000, 53.24],
        [1350856800000, 53.49],
        [1350943200000, 53.31],
        [1351029600000, 53.36],
        [1351116000000, 53.4],
        [1351202400000, 54.01],
        [1351638000000, 54.02],
        [1351724400000, 54.36],
        [1351810800000, 54.39],
        [1352070000000, 54.24],
        [1352156400000, 54.39],
        [1352242800000, 53.47],
        [1352329200000, 52.98],
        [1352415600000, 52.9],
        [1352674800000, 52.7],
        [1352761200000, 52.54],
        [1352847600000, 52.23],
        [1352934000000, 52.64],
        [1353020400000, 52.65],
        [1353279600000, 52.92],
        [1353366000000, 52.64],
        [1353452400000, 52.84],
        [1353625200000, 53.4],
        [1353884400000, 53.3],
        [1353970800000, 53.18],
        [1354057200000, 53.88],
        [1354143600000, 54.09],
        [1354230000000, 54.61],
        [1354489200000, 54.7],
        [1354575600000, 55.3],
        [1354662000000, 55.4],
        [1354748400000, 55.14],
        [1354834800000, 55.48],
        [1355094000000, 55.75],
        [1355180400000, 55.54],
        [1355266800000, 55.96],
        [1355353200000, 55.53],
        [1355439600000, 57.56],
        [1355698800000, 57.42],
        [1355785200000, 57.49],
        [1355871600000, 58.09],
        [1355958000000, 57.87],
        [1356044400000, 57.71],
        [1356303600000, 57.53],
        [1356476400000, 57.55],
        [1356562800000, 57.3],
        [1356649200000, 56.9],
        [1356908400000, 57.68],
        [1357081200000, 58.34],
        [1357167600000, 57.75],
        [1357254000000, 58.13],
        [1357513200000, 57.94],
        [1357599600000, 58.14],
        [1357686000000, 58.66],
        [1357772400000, 58.62],
        [1357858800000, 58.09],
        [1358118000000, 58.16],
        [1358204400000, 58.15],
        [1358290800000, 57.88],
        [1358377200000, 57.73],
        [1358463600000, 57.98],
        [1358809200000, 57.95],
        [1358895600000, 58.25],
        [1358982000000, 58.1],
        [1359068400000, 58.32],
        [1359327600000, 58.24],
        [1359414000000, 58.52],
        [1359500400000, 57.94],
        [1359586800000, 57.83],
        [1359673200000, 58.34],
        [1359932400000, 58.1],
        [1360018800000, 58.51],
        [1360105200000, 58.4],
        [1360191600000, 58.07],
        [1360278000000, 59.12],
        [1360537200000, 58.64],
        [1360623600000, 58.89],
        [1360710000000, 58.81],
        [1360796400000, 58.61],
        [1360882800000, 58.63],
        [1361228400000, 58.99],
        [1361314800000, 58.77],
        [1361401200000, 58.34],
        [1361487600000, 58.55],
        [1361746800000, 58.11],
        [1361833200000, 58.59],
        [1361919600000, 59.6],
      ],
    },
  ],
  options: {
    colors: ["#545454", "#1f7bc7"],
    chart: {
      id: "area-datetime",
      type: "line",
      height: 350,
      stacked: true,
      zoom: {
        autoScaleYaxis: true,
      },
    },
    title: {
      text: "SLO Value over the time",
      align: "center",
    },
    // annotations: {
    //     yaxis: [{
    //         y: 30,
    //         borderColor: '#999',
    //         label: {
    //             show: true,
    //             text: 'Support',
    //             style: {
    //                 color: "#fff",
    //                 background: '#00E396'
    //             }
    //         }
    //     }],
    //     xaxis: [{
    //         x: new Date('14 Nov 2012').getTime(),
    //         borderColor: '#999',
    //         yAxisIndex: 0,
    //         label: {
    //             show: true,
    //             text: 'Rally',
    //             style: {
    //                 color: "#fff",
    //                 background: '#775DD0'
    //             }
    //         }
    //     }]
    // },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
      style: "hollow",
    },
    xaxis: {
      type: "datetime",
      min: new Date("01 Mar 2012").getTime(),
      tickAmount: 6,
    },
    tooltip: {
      x: {
        format: "dd MMM yyyy",
      },
    },
    // fill: {
    //   type: "gradient",
    //   gradient: {
    //     shadeIntensity: 1,
    //     opacityFrom: 0.7,
    //     opacityTo: 0.9,
    //     stops: [0, 100],
    //   },
    // },
  },
  selection: "one_year",
};

export const columnChartData = {
  series: [
    {
      name: "Successful Deployments",
      // data: tempData.map(x => [x[0], Math.floor(x[1])])
      data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
    },
    {
      name: "Total Deployments",
      // data: tempData.map(x => [x[0], Math.floor(x[1] + 10)])
      data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
    },
    {
      name: "Awaiting Approval",
      // data: tempData.map(x => [x[0], Math.floor(x[1] + 20)])
      data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
    },
  ],
  options: {
    colors: ["#CC0000", "#001933", "grey"],
    chart: {
      type: "bar",
      height: 350,
    },
    title: {
      text: "Deployment Status Per Month",
      align: "center",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
        dataLabels: {
          position: "top",
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
          grid: {
            padding: {
              left: 30, // or whatever value that works
              right: 30, // or whatever value that works
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      // type: 'datetime',
      categories: [
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
      ],
      tickPlacement: "on",
    },
    yaxis: {
      title: {
        text: "Number of Deployments",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
  },
};

const dateLen = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];
export const deploymentStatusMonth = {
  series: [
    {
      name: "Successful Deployments",
      data: dateLen.map((x) => Math.floor(Math.random() * 10 + 1)),
      // data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
    },
    {
      name: "Total Deployments",
      data: dateLen.map((x) => Math.floor(Math.random() * 10 + 1)),
      // data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
    },
    {
      name: "Awaiting Approval",
      data: dateLen.map((x) => Math.floor(Math.random() * 10 + 1)),
      // data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
    },
    {
      name: "Four",
      data: dateLen.map((x) => Math.floor(Math.random() * 10 + 1)),
    },
    {
      name: "Five",
      data: dateLen.map((x) => Math.floor(Math.random() * 10 + 1)),
    },
    {
      name: "Six",
      data: dateLen.map((x) => Math.floor(Math.random() * 10 + 1)),
    },
    {
      name: "Seven",
      data: dateLen.map((x) => Math.floor(Math.random() * 10 + 1)),
    },
    {
      name: "Eight",
      data: dateLen.map((x) => Math.floor(Math.random() * 10 + 1)),
    },
    {
      name: "Nine",
      data: dateLen.map((x) => Math.floor(Math.random() * 10 + 1)),
    },
  ],
  options: {
    colors: ["#CC0000", "#001933", "grey"],
    chart: {
      type: "bar",
      height: 350,
    },
    title: {
      text: "Monthly Deployment Frequency over the time",
      align: "center",
    },
    grid: {
      padding: {
        left: 60, // or whatever value that works
        right: 60, // or whatever value that works
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
        dataLabels: {
          position: "top",
        },
        offsetY: -20,
        style: {
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
      ],
      tickPlacement: "on",
    },
    yaxis: {
      title: {
        text: "Deployments",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
  },
};

export const mttrData = {
  series: [
    {
      name: "MTTR",
      data: [
        [1327359600000, 30.95],
        [1327446000000, 31.34],
        [1327532400000, 31.18],
        [1327618800000, 31.05],
        [1327878000000, 31.0],
        [1327964400000, 30.95],
        [1328050800000, 31.24],
        [1328137200000, 31.29],
        [1328223600000, 31.85],
        [1328482800000, 31.86],
        [1328569200000, 32.28],
        [1328655600000, 32.1],
        [1328742000000, 32.65],
        [1328828400000, 32.21],
        [1329087600000, 32.35],
        [1329174000000, 32.44],
        [1329260400000, 32.46],
        [1329346800000, 32.86],
        [1329433200000, 32.75],
        [1329778800000, 32.54],
        [1329865200000, 32.33],
        [1329951600000, 32.97],
        [1330038000000, 33.41],
        [1330297200000, 33.27],
        [1330383600000, 33.27],
        [1330470000000, 32.89],
        [1330556400000, 33.1],
        [1330642800000, 33.73],
        [1330902000000, 33.22],
        [1330988400000, 31.99],
        [1331074800000, 32.41],
        [1331161200000, 33.05],
        [1331247600000, 33.64],
        [1331506800000, 33.56],
        [1331593200000, 34.22],
        [1331679600000, 33.77],
        [1331766000000, 34.17],
        [1331852400000, 33.82],
        [1332111600000, 34.51],
        [1332198000000, 33.16],
        [1332284400000, 33.56],
        [1332370800000, 33.71],
        [1332457200000, 33.81],
        [1332712800000, 34.4],
        [1332799200000, 34.63],
        [1332885600000, 34.46],
        [1332972000000, 34.48],
        [1333058400000, 34.31],
        [1333317600000, 34.7],
        [1333404000000, 34.31],
        [1333490400000, 33.46],
        [1333576800000, 33.59],
        [1333922400000, 33.22],
        [1334008800000, 32.61],
        [1334095200000, 33.01],
        [1334181600000, 33.55],
        [1334268000000, 33.18],
        [1334527200000, 32.84],
        [1334613600000, 33.84],
        [1334700000000, 33.39],
        [1334786400000, 32.91],
        [1334872800000, 33.06],
        [1335132000000, 32.62],
        [1335218400000, 32.4],
        [1335304800000, 33.13],
        [1335391200000, 33.26],
        [1335477600000, 33.58],
        [1335736800000, 33.55],
        [1335823200000, 33.77],
        [1335909600000, 33.76],
        [1335996000000, 33.32],
        [1336082400000, 32.61],
        [1336341600000, 32.52],
        [1336428000000, 32.67],
        [1336514400000, 32.52],
        [1336600800000, 31.92],
        [1336687200000, 32.2],
        [1336946400000, 32.23],
        [1337032800000, 32.33],
        [1337119200000, 32.36],
        [1337205600000, 32.01],
        [1337292000000, 31.31],
        [1337551200000, 32.01],
        [1337637600000, 32.01],
        [1337724000000, 32.18],
        [1337810400000, 31.54],
        [1337896800000, 31.6],
        [1338242400000, 32.05],
        [1338328800000, 31.29],
        [1338415200000, 31.05],
        [1338501600000, 29.82],
        [1338760800000, 30.31],
        [1338847200000, 30.7],
        [1338933600000, 31.69],
        [1339020000000, 31.32],
        [1339106400000, 31.65],
        [1339365600000, 31.13],
        [1339452000000, 31.77],
        [1339538400000, 31.79],
        [1339624800000, 31.67],
        [1339711200000, 32.39],
        [1339970400000, 32.63],
        [1340056800000, 32.89],
        [1340143200000, 31.99],
        [1340229600000, 31.23],
        [1340316000000, 31.57],
        [1340575200000, 30.84],
        [1340661600000, 31.07],
        [1340748000000, 31.41],
        [1340834400000, 31.17],
        [1340920800000, 32.37],
        [1341180000000, 32.19],
        [1341266400000, 32.51],
        [1341439200000, 32.53],
        [1341525600000, 31.37],
        [1341784800000, 30.43],
      ],
    },
  ],
  options: {
    colors: ["#001933"],
    chart: {
      type: "area",
      stacked: false,
      height: 350,
      zoom: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    title: {
      text: "Mean Time To Resolve (MTTR)",
      align: "center",
    },
    // fill: {
    //     type: 'gradient',
    //     gradient: {
    //         shadeIntensity: 1,
    //         inverseColors: false,
    //         opacityFrom: 0.5,
    //         opacityTo: 1,
    //         stops: [0, 90, 100]
    //     },
    // },
    yaxis: {
      title: {
        text: "Price",
      },
      colors: ["#fff"],
    },
    xaxis: {
      type: "datetime",
    },
  },
};

export const incidentsData = {
  series: [
    {
      name: "Closed",
      data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
    },
    {
      name: "Open",
      data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
    },
    {
      name: "Total Incidents",
      data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
    },
  ],
  options: {
    colors: ["#001933", "#CC0000", "grey"],
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
    },
    title: {
      text: "Incidents Reported v/s Resolved",
      align: "center",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
        dataLabels: {
          position: "top",
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
          grid: {
            padding: {
              left: 30, // or whatever value that works
              right: 30, // or whatever value that works
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
      ],
      tickPlacement: "on",
    },
    yaxis: {
      title: {
        text: "Number of Incidents",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
  },
};

export const incidentsMixedChartData = {
  optionsMixedChart: {
    colors: ["grey", "#001933", "#CC0000"],
    title: {
      text: "Incidents Reported vs Resolved",
      align: "center",
    },
    chart: {
      id: "basic-bar",
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
        endingShape: "rounded",
      },
    },
    stroke: {
      width: [4, 0, 0],
    },
    xaxis: {
      categories: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    },
    markers: {
      size: 6,
      strokeWidth: 3,
      fillOpacity: 0,
      strokeOpacity: 0,
      hover: {
        size: 8,
      },
    },
    yaxis: {
      tickAmount: 5,
      min: 0,
      max: 100,
    },
  },
  seriesMixedChart: [
    {
      name: "Total incidents",
      type: "line",
      data: [85, 60, 99, 85, 80, 66, 31, 62],
    },
    {
      name: "Open Incidents",
      type: "column",
      data: [23, 30, 54, 30, 23, 25, 8, 19],
    },
    {
      name: "Close Incidents",
      type: "column",
      data: [62, 30, 45, 55, 57, 41, 23, 43],
    },
  ],
};

export const sparkline1Data = {
  type: "area",
  height: 200,
  options: {
    chart: {
      sparkline: {
        enabled: true,
      },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
            enabled: true,
            delay: 150
        },
        dynamicAnimation: {
            enabled: true,
            speed: 350
        }
      }
    },
    markers: {
      size: 4,
      hover: {
        size: 5,
      }
    },
    dataLabels: {
      enabled: false,
    },
    colors:['#9C27B0'],
    fill: {
      type: "solid",
      opacity: 0.4,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    yaxis: {
      show: false
    },
    xaxis: {
      show: false
    },
    grid: {
      show: false,
      padding: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        // console.log(series, seriesIndex, dataPointIndex, w);
        
        return (
          '<div style="padding: 10px">' +
          "<span>" +
          w.globals.categoryLabels[dataPointIndex] +
          ": " +
          series[seriesIndex][dataPointIndex] +
          "</span>" +
          "</div>"
        );
      },
      theme: "dark",
      fixed: {
        enabled: false,
      },
    },
  },
};

export const AreaGraphWithAnnotation = {
  type: "area",
  height: 200,
  options: {
    annotations: {
      position: "back",
      yaxis: [
        {
          label: {
            text: " "
          },
          y: 80,
          y2: 100,
          fillColor: "#388e3c"
        },
        {
          label: {
            text: " "
          },
          y: 60,
          y2: 80,
          fillColor: "#ffee58"
        },
        {
          label: {
            text: " "
          },
          y: 0,
          y2: 60,
          fillColor: "#ff7043"
        }
      ]
    },
    chart: {
      toolbar: {
        show: false,
      },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
            enabled: true,
            delay: 150
        },
        dynamicAnimation: {
            enabled: true,
            speed: 350
        }
      }
    },
    markers: {
      size: 4,
      hover: {
        size: 5,
      }
    },
    dataLabels: {
      enabled: false,
    },
    colors:['#757575'],
    fill: {
      type: "solid",
      opacity: 0,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    grid: {
      show: false,
      padding: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        // // console.log(series, seriesIndex, dataPointIndex, w);
        
        return (
          '<div style="padding: 10px">' +
          "<span>" +
          w.globals.categoryLabels[dataPointIndex] +
          ": " +
          series[seriesIndex][dataPointIndex] +
          "</span>" +
          "</div>"
        );
      },
      theme: "dark",
      fixed: {
        enabled: false,
      },
    },
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'left',
      offsetX: -10,
    },
    yaxis: {
      labels: {
          style: {
              colors: '#616161',
          },
      },
    },
    xaxis: {
      labels: {
          style: {
              colors: '#616161',
          },
      },
    },
  },
};

export const pieData = {
  series: [44, 55, 13, 43, 22],
  type: "pie",
  height: 85,
  options: {

    dataLabels: {
      enabled: false,
    },

    fill: {
      type: "solid",
      opacity: 1,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
  },
};
export const sparkline2Data = {
  type: "area",
  height: 85,
  options: {
    chart: {
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 4,
      hover: {
        size: 5,
      }
    },
    dataLabels: {
      enabled: false,
    },
    colors: [theme.colors.secondary],
    fill: {
      type: "solid",
      opacity: 1,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    grid: {
      show: false,
      padding: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10
      },
    },
    tooltip: {
      theme: "dark",
      fixed: {
        enabled: false,
      },
      x: {
        show: false,
      },
      y: {
        show: false,
      },
      marker: {
        show: false,
      },
    },
  },
  series: [
    {
      name: "YEAR",
      data: [35, 44, 9, 54, 45, 66, 41, 69],
    },
  ],
};

export const sparklineDatav2 = (card) => {
  return ({
    type: "area",
    height: 200,
    options: {
      chart: {
        toolbar: {
          show: false,
        },
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 800,
          animateGradually: {
              enabled: true,
              delay: 150
          },
          dynamicAnimation: {
              enabled: true,
              speed: 350
          }
        }
      },
      markers: {
        size: 4,
        hover: {
          size: 5,
        }
      },
      dataLabels: {
        enabled: false,
      },
      colors:[...card.chartColor],
      fill: {
        type: "solid",
        opacity: 0.5,
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      grid: {
        show: false,
        padding: {
          top: 10,
          right: 10,
          bottom: 10,
          left: 10
        },
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          // // console.log(series, seriesIndex, dataPointIndex, w);
          
          return (
            '<div style="padding: 10px">' +
            "<span>" +
            w.globals.categoryLabels[dataPointIndex] +
            ": " +
            series[seriesIndex][dataPointIndex] +
            "</span>" +
            "</div>"
          );
        },
        theme: "dark",
        fixed: {
          enabled: false,
        },
      },
      yaxis: {
        labels: {
            style: {
                colors: theme.colors.secondary,
            },
        },
      },
      xaxis: {
        labels: {
            style: {
                colors: theme.colors.secondary,
            },
        },
      },
    },
  });
}

export const colorData = (card) => {
  const data = {
    "cloudcost": {
      backgroundColor: theme.colors.atmo1,
      chartColor: ["#546e7a", "#ff7043"],
      fontColor: theme.colors.secondary,
      positive: theme.colors.negative,
      negative: theme.colors.positive,
      shadow: "#424242",
      compareChartColor: ["#ff9600", "#4285f4"],
    },
    "deployments": {
      backgroundColor: theme.colors.atmo1,
      chartColor: ["#546e7a", "#ff7043"],
      fontColor: theme.colors.secondary,
      positive: theme.colors.positive,
      negative: theme.colors.negative,
      shadow: "#424242",
      compareChartColor: ["#ff9600", "#4285f4"],
    },
    "mttr": {
      backgroundColor: theme.colors.atmo1,
      chartColor: ["#546e7a", "#ff7043"],
      fontColor: theme.colors.secondary,
      positive: theme.colors.negative,
      negative: theme.colors.positive,
      shadow: "#424242",
      compareChartColor: ["#ff9600", "#4285f4"],
    },
    "slo": {
      backgroundColor: theme.colors.atmo1,
      chartColor: ["#546e7a", "#ff7043"],
      fontColor: theme.colors.secondary,
      positive: theme.colors.positive,
      negative: theme.colors.negative,
      shadow: "#424242",
      compareChartColor: ["#ff9600", "#4285f4"],
    },
    "deployments count": {
      backgroundColor: theme.colors.atmo1,
      chartColor: ["#546e7a", "#ff7043"],
      fontColor: theme.colors.secondary,
      positive: theme.colors.positive,
      negative: theme.colors.negative,
      shadow: "#424242",
      compareChartColor: ["#ff9600", "#4285f4"],
    },
    "mtta": {
      backgroundColor: theme.colors.atmo1,
      chartColor: ["#546e7a", "#ff7043"],
      fontColor: theme.colors.secondary,
      positive: theme.colors.negative,
      negative: theme.colors.positive,
      shadow: "#424242",
      compareChartColor: ["#ff9600", "#4285f4"],
    },
    "successful deployments": {
      backgroundColor: theme.colors.atmo1,
      chartColor: ["#546e7a", "#ff7043"],
      fontColor: theme.colors.secondary,
      positive: theme.colors.positive,
      negative: theme.colors.negative,
      shadow: "#424242",
      compareChartColor: ["#ff9600", "#4285f4"],
    },
    "incidents": {
      backgroundColor: theme.colors.atmo1,
      chartColor: ["#546e7a", "#ff7043"],
      fontColor: theme.colors.secondary,
      positive: theme.colors.positive,
      negative: theme.colors.negative,
      shadow: "#424242",
      compareChartColor: ["#ff9600", "#4285f4"],
    },
    "comments": {
      backgroundColor: theme.colors.atmo1,
      chartColor: ["#546e7a", "#ff7043"],
      fontColor: theme.colors.secondary,
      positive: theme.colors.positive,
      negative: theme.colors.negative,
      shadow: "#424242",
      compareChartColor: ["#ff9600", "#4285f4"],
    },
    "hcap operate usage": {
      backgroundColor: theme.colors.atmo1,
      chartColor: ["#546e7a", "#ff7043"],
      fontColor: theme.colors.secondary,
      positive: theme.colors.positive,
      negative: theme.colors.negative,
      shadow: "#424242",
      compareChartColor: ["#ff9600", "#4285f4"],
    },
    "changefailurerate": {
      backgroundColor: theme.colors.atmo1,
      chartColor: ["#546e7a", "#ff7043"],
      fontColor: theme.colors.secondary,
      positive: theme.colors.negative,
      negative: theme.colors.positive,
      shadow: "#424242",
      compareChartColor: ["#ff9600", "#4285f4"],
    }
    }

    if (data[card.toLowerCase()]) {
      return (data[card.toLowerCase()]);
    } else {
      return {
        backgroundColor: theme.colors.atmo1,
        chartColor: ["#546e7a", "#ff7043"],
        fontColor: theme.colors.secondary,
        positive: theme.colors.positive,
        negative: theme.colors.negative,
        shadow: "#424242",
        compareChartColor: ["#ff9600", "#4285f4"],
      }
    }
};

export const compareChartDataData = (card) => { return({
  type: "area",
  height: 250,
  options: {
    chart: {
      // sparkline: {
      //   enabled: true,
      // },
      toolbar: {
        show: false,
      },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
            enabled: true,
            delay: 150
        },
        dynamicAnimation: {
            enabled: true,
            speed: 350
        }
      }
    },
    markers: {
      size: 4,
      hover: {
        size: 5,
      }
    },
    dataLabels: {
      enabled: false,
    },
    colors:[...card.compareChartColor],
    fill: {
      type: "solid",
      opacity: 0,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    grid: {
      show: false,
      padding: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        // console.log(series, seriesIndex, dataPointIndex, w);
        // console.log(w.config.series[seriesIndex].data[dataPointIndex]['label'])
        
        return (
          '<div style="padding: 10px">' +
          "<span>" +
          w.config.series[seriesIndex].data[dataPointIndex]['label'] +
          ": " +
          series[seriesIndex][dataPointIndex] +
          "</span>" +
          "</div>"
        );
      },
      theme: "dark",
      fixed: {
        enabled: false,
      },
    },
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'left',
      offsetX: -10,
      marginTop: 10
    },
    xaxis: {
      type: "datetime",
      tooltip: {
        enabled: false,
    },
    },
  },
})
};