import { Box } from "@mui/material";

import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Router from "./components/Routing/Router";
import { useState } from "react";
import { HvProvider } from "@hitachivantara/uikit-react-core";
import { SettingsContext } from "./common/SettingsContext/settingsContext";
import { AuthProvider } from "./hooks/useAuth";

function App() {
  const [settingsctx, setSettingsctx] = useState(false);

  return (
    <HvProvider>
      <SettingsContext.Provider value={[settingsctx, setSettingsctx]}>
        <Box sx={{ maxHeight: "100%", height: "100%" }}>
          <div className="App">
            <BrowserRouter>
              <AuthProvider>
                <Router />
              </AuthProvider>
            </BrowserRouter>
          </div>
        </Box>
      </SettingsContext.Provider>
    </HvProvider>
  );
}

export default App;
