import * as React from 'react';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { dateDiffInDays } from '../../../utils/helper';
import { theme } from '@hitachivantara/uikit-react-core';
 
const columns = [
  { id: 'x', label: 'Periods', minWidth: 110 },
  { id: 'y', label: 'Values', minWidth: 150 },
  { id: 'yy', label: 'Values', minWidth: 150 },
  { id: 'xx', label: 'Periods', minWidth: 115 },
];

export default function CompareTable(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);

  const getMonts = () => {
    const months = Math.floor(dateDiffInDays(props.range[0].startDate, props.range[0].endDate) / 30)
    return months + 1
  }
  
  React.useEffect(() => {

    const formatVal = (val) => {
        switch(props.name.toLowerCase()) {
            // case 'cloudcost':
            //     return (val/1000000)
            default:
                return val
        }
    }

    let chartDataPoints = props.chartData
    .map((x) => ({
      x: new Date(x.date).toLocaleString(
        "default",
        getMonts() && getMonts() <= 12
          ? { month: "short", year: "numeric" }
          : { year: "numeric" }
      ),
      y: props.metricCardValue.prefix + formatVal(Math.trunc(x.value))  + props.metricCardValue.suffix,
    }))
    .sort((a, b) => (Date.parse(b.x) > Date.parse(a.x) ? 1 : -1));


    const prevChartDataPoints = props.prevPeriodDashboardMetrics.chartData
    .map((x) => ({
      xx: new Date(x.date).toLocaleString(
        "default",
        getMonts() && getMonts() <= 12
          ? { month: "short", year: "numeric" } 
          : { year: "numeric" }
      ),
      yy: props.metricCardValue.prefix + formatVal(Math.trunc(x.value)) + props.metricCardValue.suffix,
    }))
    .sort((a, b) => (Date.parse(b.x) > Date.parse(a.x) ? 1 : -1));


    chartDataPoints = chartDataPoints.reverse();
    let rowsData = [...chartDataPoints]
    // console.log(prevChartDataPoints);
    for(let i=0; i<prevChartDataPoints.length; i++ ) {
        if(!rowsData[i]) {
            rowsData.push({...prevChartDataPoints[i]});
        }else {
            rowsData[i] = {...rowsData[i], ...prevChartDataPoints[i]}
        }
    }

    setRows(rowsData);
  },[props.chartData, props.prevPeriodDashboardMetrics]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Card sx={{borderRadius: 0}}>
      <TableContainer sx={{ height: '341px'}}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, color: theme.colors.secondary, 
                  fontSize: '1.00rem', fontWeight: '600' }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
}