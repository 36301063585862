import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './DateRangePicker.css';

import { DateRangePicker, defaultStaticRanges } from 'react-date-range';
import { addMonths, addYears, startOfDay, endOfDay, isSameDay, startOfMonth } from 'date-fns';
import { useState } from 'react';

    const EOD = endOfDay(new Date());
    const SOD = startOfDay(new Date());

    const staticRangeHandler = {
        range: {},
        isSelected(range) {
          const definedRange = this.range();
          return (
            isSameDay(range.startDate, definedRange.startDate) &&
            isSameDay(range.endDate, definedRange.endDate)
          );
        },
      };
      
      function createStaticRanges(ranges) {
        return ranges.map(range => ({ ...staticRangeHandler, ...range }));
      }
    
    export const customranges = createStaticRanges([{
            label: "This Month",
            range: () => ({
                startDate: startOfMonth(new Date()),
                endDate: startOfDay(new Date()),
            }),
        },
        {
            label: "Last Month",
            range: () => ({
                startDate: startOfMonth(new Date()),
                endDate: startOfDay(new Date()),
            }),
        },
        {
            label: "Three Month",
            range: () => ({
                startDate: startOfMonth(addMonths(new Date(), -2)),
                endDate: startOfDay(new Date()),
            }),
        },
        {
            label: 'Six Month',
            range: () => ({
                startDate: startOfMonth(addMonths(new Date(), -5)),
                endDate: startOfDay(new Date()),
            }),
        },
        {
            label: "One Year",
            range: () => ({
            startDate: addYears(new Date(), -1),
            endDate: new Date(),
            }),
        },
        {
            label: "Two Year",
            range: () => ({
            startDate: addYears(new Date(), -2),
            endDate: new Date(),
            }),
        },
        // {
        //     label: "All Time",
        //     range: () => ({
        //     startDate: null,
        //     endDate: new Date()
        //     }),
        // },
    ]);

export const DatePickerRange = (props) => {
    const [state, setState] = useState([
        {
          startDate: new Date(),
          endDate: addMonths(new Date(), 1),
          key: 'selection'
        }
      ]);

    const totalRages= [ ...customranges ]

      return (
      <DateRangePicker
        onChange={item => {
            setState([item.selection]);
            props.onRangeSelect([item.selection]);
            props.close();
        }}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        ranges={state}
        direction="horizontal"
        staticRanges={totalRages}
        editableDateInputs
        inputRanges={[]}
      />
      );
}