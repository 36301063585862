// material-ui
import {
  Box, 
  Card,
  Grid,
  Typography,
} from "@mui/material";
import ReactApexChart from "react-apexcharts";
import {
  AreaGraphWithAnnotation,
  compareChartDataData,
  colorData
} from "../../../components/Charts/ChartsData";
import { dateDiffInDays } from "../../../utils/helper";
import { theme } from '@hitachivantara/uikit-react-core';

const CompareChart = (props) => {

  const getMonts = () => {
    const months = Math.floor(dateDiffInDays(props.range[0].startDate, props.range[0].endDate) / 30)
    return months + 1
  }
  const prepareChartPoints = (chartData, prevChartData=null) => {
    const label = (x,i) => {
        return new Date(x.date).toLocaleString(
            "default",
            { month: "short", year: "numeric" }
          )
    }

    const preparedData = chartData
    .map((x,i) => ({
      x: new Date(x.date).toLocaleString(
        "default",
        { month: "short", year: "numeric" }
      ),
      y: Math.trunc(x.value),
      ...{label: label(x,i)}
    }))
    .sort((a, b) => (Date.parse(b.x) > Date.parse(a.x) ? 1 : -1));
    return preparedData
  }

  const chartDataPoints = prepareChartPoints(props.chartData);
  const prevData = props.prevPeriodDashboardMetrics.chartData;
  const prevPreiodChartDataPoints = prepareChartPoints(prevData, props.chartData);
  // console.log(prevPreiodChartDataPoints);

  const colorScheme = colorData(props.name);


  const getGraphSettingOptionsAno = () => {
      return {...AreaGraphWithAnnotation, height: 200};
  };

  const getGraphSettingOptions = () => {
    return compareChartDataData(colorScheme);
  }; 

  return (
    <Card sx={{ borderRadius: "0px", width: 'auto!important'}}>
      <Box sx={{ p: 3, backgroundColor: "#fafafa" }}>
        <Grid direction="row" spacing={2}>
          <Grid
            item
            container
            direction="row"
            justifyContent="space-between"
            sx={{}}
          >
            <Grid item alignSelf="flex-start" sx={{maxWidth: '60%', minHeight: '80px' }}>
              <Typography
                variant="h5"
                sx={{
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "1.25rem",
                  color: theme.colors.secondary,
                }}
              >
                { "Collated " + props.metricCardTitle || ""}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  mr: 1,
                  mb: 0.75,
                  fontSize: "0.675rem",
                  fontWeight: 400,
                  color: theme.colors.secondary,
                }}
              >
                {props.metricCardSubTitle || ""}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  mr: 1,
                  mb: 0.75,
                  fontSize: "0.675rem",
                  fontWeight: 400,
                  color: "#616161",
                }}
              >
                {`Shows collated data for metric selected.`}
              </Typography>
            </Grid>
            <Grid direction="column" sx={{}} item alignSelf="flex-start">
              <Grid item>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 600,
                    fontSize: "1.25rem",
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    color: "#616161",
                  }}
                >
                  {props.metricCardValue !== null
                    ? props.metricCardValue.prefix +
                      props.metricCardValue.value +
                      props.metricCardValue.suffix
                    : "-"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid item xs={12}>
              <Grid
                justifyContent="space-between"
                spacing={1}
                sx={{ textAlign: { xs: "center", md: "left" } }}
              >
                <Grid item xs={12}>
                  <Box sx={{ justifyContent: "space-between", mx: "auto" }}>
                   {props.prevPeriodDashboardMetrics && props.prevPeriodDashboardMetrics.name === "SLO" 
                    ? (<ReactApexChart testdata={'ANOTATION'}
                      {...getGraphSettingOptionsAno()}
                      series={[{name: `Current ${getMonts()} Months Data`, data: chartDataPoints }, {name: `Previous ${getMonts()} Months Data`, data: prevPreiodChartDataPoints }]}
                    />) 
                    : (<ReactApexChart
                        {...getGraphSettingOptions()}
                        series={[{name: `Current ${getMonts()} Months Data`, data: chartDataPoints }, {name: `Previous ${getMonts()} Months Data`, data: prevPreiodChartDataPoints }]}
                      />)
                    }
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default CompareChart;
