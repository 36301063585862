import FileInput from '../../components/FileInput/FileInput';
import { useState } from 'react';
import Papa from 'papaparse';
import FullFeaturedCrudGrid from './DataGridTable';
import { Button, Card, Collapse, Divider, Grid, List, ListItemButton, ListItemText, TextField } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { formMetricsData } from '../../utils/constants/constants';
// import EditableTable from '../../components/EditableTable/EditableTable';

const createFormValues = (val) => {
    return ({ [val.name]: '' })
}

const DataUpload = () => {

    const [cols, setCols] = useState([]);
    const [rows, setRows] = useState([]);
    const [showTable, setShowTable] = useState(false);
    const [dashboardMetricsForm, setDashboardMetricsForm] = useState({ timestamp: dayjs(), ...formMetricsData.map(x => createFormValues(x)) });
    // const [selectedFile, setSelectedFile] = useState(null);
    // const [isLoading, setIsLoading] = useState(false);

    const updateData = (result, file) => {
        // console.log("Papaparse", result.data);
        // console.log("file: ", file);
        setCols(Object.keys(result.data[0]));
        setRows(result.data);
        // setIsLoading(false);
    }

    const importCSV = (e) => {
        e.preventDefault();
        let file = e.target.files[0];
        if (file.type === 'text/csv') {
            setShowTable(true);
            // setIsLoading(true);
            Papa.parse(e.target.files[0], {
                complete: (results, file) => updateData(results, file),
                header: true,
                skipEmptyLines: true
            });
        }
        else {
            setShowTable(false);
            alert("Please Upload CSV files.");
            e.target.value = null;
            // console.log(e.target);
        }
    };

    const onSubmit = (e) => {
        e.preventDefault();
        // console.log("Submit: ", rows);
    }

    // console.log("Columns: ", cols);
    // console.log("Rows: ", rows);

    const [openClient, setOpenClient] = useState(true);
    const [openCommon, setOpenCommon] = useState(true);

    const handleClick = (val) => {
        val === 'common' ? setOpenCommon(!openCommon) : setOpenClient(!openClient)
    };

    const handleDashboardMetrics = (e) => {
        // console.log("formValues: ", dashboardMetricsForm);
        setDashboardMetricsForm({ ...dashboardMetricsForm, [e.target.name]: e.target.value });
    }

    const updatedashboardMetrics = (e) => {
        e.preventDefault();
        // console.log("Dashboard Values: ", dashboardMetricsForm);
    }
    // console.log("Dashboard Values (out): ", dashboardMetricsForm, dashboardMetricsForm.timestamp.$d);

    return (
        <div>
            <Card sx={{ p: 3, my: 1 }} xs={12} md={12}>
                <List>
                    <ListItemButton onClick={() => handleClick('common')} sx={{ backgroundColor: '#eef2f6', mt: 1 }}>
                        <ListItemText primary="Update Dashboard Metrics" />
                        {openCommon ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openCommon} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemText sx={{ p: 3 }}>
                                <form onSubmit={(e) => updatedashboardMetrics(e)} style={{ width: '100%' }}>
                                    <Grid container spacing={2} alignItems='center'>
                                        <Grid item md={12} sm={12} xs={12}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DateTimePicker label="Timestamp" name='timestamp' />
                                            </LocalizationProvider>
                                            <Divider sx={{ mt: 2, display: {
                                                xs: 'block',
                                                md: 'none'
                                            } }} />
                                        </Grid>
                                        {
                                            formMetricsData.map(x => {
                                                return (
                                                    <Grid item md={6} sm={12} xs={12} key={x.name}>
                                                        <TextField variant='outlined' sx={{ width: '100%' }} value={dashboardMetricsForm[x.name]} onChange={(e) => handleDashboardMetrics(e)} {...x} />
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                    <br />
                                    <Button variant='contained' type="submit" sx={{ backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: 'black', color: 'white' } }}>Submit</Button>
                                </form>
                            </ListItemText>
                        </List>
                    </Collapse>
                </List>
            </Card>
            <Card sx={{ p: 3, my: 1 }} xs={12} md={12}>
                <List>
                    <ListItemButton onClick={() => handleClick('client')} sx={{ backgroundColor: '#eef2f6', mt: 1 }}>
                        <ListItemText primary="Update Account Dashboard Metrics" />
                        {openClient ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openClient} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemText sx={{ p: 3, }}>
                                <form onSubmit={(e) => onSubmit(e)} style={{ width: '100%' }}>
                                    <FileInput importCSV={importCSV} />
                                    {showTable && <><br /><Card sx={{ p: 3 }}><FullFeaturedCrudGrid rows={rows} setRows={setRows} /></Card></>}
                                    <br />
                                    <Button variant='contained' type="submit" sx={{ backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: 'black', color: 'white' } }}>Submit</Button>
                                    {/* {showTable && <EditableTable cols={cols} rows={rows} isLoading={isLoading} />} */}
                                </form>
                            </ListItemText>
                        </List>
                    </Collapse>
                </List>
            </Card>
        </div>
    )
}

export default DataUpload;