import ApexCharts from 'apexcharts';
import ReactApexChart from "react-apexcharts";
import React, { useState } from "react";
import { Button } from '@mui/material';

const AreaChartDatetime = ({ areaChartTimelineData }) => {
    const [data, setData] = useState(areaChartTimelineData);
    const [selection, setSelection] = useState('');
    
    const updateData = (timeline) => {

        setSelection(timeline);

        switch (timeline) {
            case 'one_month':
                ApexCharts.exec(
                    'area-datetime',
                    'zoomX',
                    new Date('28 Jan 2013').getTime(),
                    new Date('27 Feb 2013').getTime()
                )
                break
            case 'six_months':
                ApexCharts.exec(
                    'area-datetime',
                    'zoomX',
                    new Date('27 Sep 2012').getTime(),
                    new Date('27 Feb 2013').getTime()
                )
                break
            case 'one_year':
                ApexCharts.exec(
                    'area-datetime',
                    'zoomX',
                    new Date('27 Feb 2012').getTime(),
                    new Date('27 Feb 2013').getTime()
                )
                break
            case 'ytd':
                ApexCharts.exec(
                    'area-datetime',
                    'zoomX',
                    new Date('01 Jan 2013').getTime(),
                    new Date('27 Feb 2013').getTime()
                )
                break
            case 'all':
                ApexCharts.exec(
                    'area-datetime',
                    'zoomX',
                    new Date('23 Jan 2012').getTime(),
                    new Date('27 Feb 2013').getTime()
                )
                break
            default:
        }
    }

    return (
        <div id="chart">
            <div className="toolbar">
                <Button variant='outlined' id="one_month"
                    onClick={() => updateData('one_month')} className={(selection === 'one_month' ? 'active' : '')}>
                    1M
                </Button>
                &nbsp;
                <Button variant='outlined' id="six_months"
                    onClick={() => updateData('six_months')} className={(selection === 'six_months' ? 'active' : '')}>
                    6M
                </Button>
                &nbsp;
                <Button variant='outlined' id="one_year"
                    onClick={() => updateData('one_year')} className={(selection === 'one_year' ? 'active' : '')}>
                    1Y
                </Button>
                &nbsp;
                <Button variant='outlined' id="ytd"
                    onClick={() => updateData('ytd')} className={(selection === 'ytd' ? 'active' : '')}>
                    YTD
                </Button>
                &nbsp;
                <Button variant='outlined' id="all"
                    onClick={() => updateData('all')} className={(selection === 'all' ? 'active' : '')}>
                    ALL
                </Button>
            </div>

            <div id="chart-timeline">
                <ReactApexChart options={data.options} series={data.series} type="line" height={350} />
            </div>
        </div>
    )
}

export default AreaChartDatetime;

