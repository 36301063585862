import { Box, Breadcrumbs, Card, CardContent, CardHeader, Divider, Grid, IconButton, Link, Tooltip, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import AreaChartDatetime from "../../components/Charts/AreaChartDatetime";
import { areaChartTimelineData, changeFailureRateData, columnChartData, deploymentStatusMonth, errorBudgetData, incidentsMixedChartData, mttdData, mttrData, radialChartData, slaData } from "../../components/Charts/ChartsData";
import ColumnChart from "../../components/Charts/ColumnChart";
import AreaChart from "../../components/Charts/AreaChart";
import MixedBarLineChart from "../../components/Charts/MixedBarLineChart";
import RadialChart from "../../components/Charts/RadialChart";
import ReactApexChart from "react-apexcharts";
import InfoIcon from '@mui/icons-material/Info';

const ClientDashboard = () => {
    const { clientName } = useParams();
    // console.log("route: ", clientName);
    return (
        <>
            <br />
            <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sx={{ p: 2 }}>
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <Link underline="none" color="text.primary">
                            <Typography variant='h6' sx={{ fontWeight: 600 }}>Clients</Typography>
                        </Link>
                        <Link
                            underline="none"
                            color="text.primary"
                            aria-current="page"
                        >
                            <Typography variant='h6' sx={{ fontWeight: 600 }}>{clientName}</Typography>
                        </Link>
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12} sx={{ p: 2 }}>
                    <Card sx={{ borderRadius: '20px', backgroundColor: 'white' }} elevation={0}>
                        <Box sx={{ textAlign: '-webkit-center' }}>
                            <Card sx={{ mb: 2, borderRadius: '20px', textAlign: 'center', backgroundColor: '#e2e2e9', color: 'black', width: { xs: '100%', md: '100%' } }} elevation={0}>
                                <Typography variant='h6' sx={{ p: 2, fontWeight: 700 }}>Service Level Objective (SLO)</Typography>
                            </Card>
                        </Box>
                        <Card elevation={0} sx={{ p: 3, backgroundColor: '#e2e2e9', borderRadius: '20px' }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={4}>
                                    <Card sx={{ py: 3, height: '100%', borderRadius: '20px' }} elevation={0}>
                                        <RadialChart radialChartData={radialChartData} />
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Card sx={{ p: 3, borderRadius: '20px' }} elevation={0}>
                                        <AreaChartDatetime areaChartTimelineData={areaChartTimelineData} />
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={4} >
                                    <Card sx={{ p: 1, height: '100%', borderRadius: '20px', textAlign: 'center' }} elevation={0}>
                                        <CardHeader sx={{ p: 0 }} title={
                                            <>
                                                <Box sx={{ textAlign: 'right' }}>
                                                    <Tooltip title={<Typography variant="subtitle2" sx={{ fontSize: '.8rem' }}>{`(No. of Successful Requests / Total No. of Requests Received) * 100`}</Typography>} >
                                                        <IconButton>
                                                            <InfoIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>
                                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Availability %</Typography>
                                            </>
                                        } />
                                        <CardContent><Typography variant="h3">95</Typography></CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={4} >
                                    <Card sx={{ p: 1, height: '100%', borderRadius: '20px', textAlign: 'center', alignItems: 'center' }} elevation={0}>
                                        <CardHeader sx={{ p: 0 }} title={
                                            <>
                                                <Box sx={{ textAlign: 'right' }}>
                                                    <Tooltip title={<Typography variant="subtitle2" sx={{ fontSize: '.8rem' }}>95th percentile response time of a request</Typography>}>
                                                        <IconButton>
                                                            <InfoIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>
                                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Latency (ms / s)</Typography></>
                                        } />
                                        <CardContent><Typography variant="h3">10 s</Typography></CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={4} >
                                    <Card sx={{ p: 1, height: '100%', borderRadius: '20px', textAlign: 'center', alignItems: 'center' }} elevation={0}>
                                        <ReactApexChart options={errorBudgetData.options} type="pie" series={errorBudgetData.series} height={200} />
                                    </Card>
                                </Grid>
                            </Grid>
                        </Card>
                    </Card>
                    <Divider sx={{ mt: 3 }} />
                </Grid>
                <Grid item xs={12} sx={{ p: 2 }}>
                    <Card sx={{ borderRadius: '20px', backgroundColor: 'white' }} elevation={0}>
                        <Box sx={{ textAlign: '-webkit-center' }}>
                            <Card sx={{ mb: 2, borderRadius: '20px', textAlign: 'center', backgroundColor: '#e2e2e9', color: 'black', width: { xs: '100%', md: '100%' } }} elevation={0}>
                                <Typography variant='h6' sx={{ p: 2, fontWeight: 700 }}>RELEASE MANAGEMENT</Typography>
                            </Card>
                        </Box>
                        <Card elevation={0} sx={{ p: 3, backgroundColor: '#e2e2e9', borderRadius: '20px' }}>
                            <Grid container spacing={3} alignItems="stretch">
                                <Grid item xs={12} md={8} >
                                    <Card sx={{ p: 3, borderRadius: '20px' }} elevation={0}>
                                        <ColumnChart chartData={columnChartData} />
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={4} >
                                    <Card sx={{ py: 3, height: '100%', borderRadius: '20px' }} elevation={0}>
                                        <ReactApexChart options={changeFailureRateData.options} type="radialBar" series={changeFailureRateData.series} height={350} />
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={12} >
                                    <Card sx={{ p: 3, borderRadius: '20px' }} elevation={0}>
                                        <ColumnChart chartData={deploymentStatusMonth} />
                                    </Card>
                                </Grid>
                            </Grid>
                        </Card>
                    </Card>
                    <Divider sx={{ mt: 3 }} />
                </Grid>
                <Grid item xs={12} sx={{ p: 2 }}>
                    <Card sx={{ borderRadius: '20px', backgroundColor: 'white' }} elevation={0}>
                        <Box sx={{ textAlign: '-webkit-center' }}>
                            <Card sx={{ mb: 2, borderRadius: '20px', textAlign: 'center', backgroundColor: '#e2e2e9', color: 'black', width: { xs: '100%', md: '100%' } }} elevation={0}>
                                <Typography variant='h6' sx={{ p: 2, fontWeight: 700 }}>INCIDENT MANAGEMENT</Typography>
                            </Card>
                        </Box>
                        <Card elevation={0} sx={{ p: 3, backgroundColor: '#e2e2e9', borderRadius: '20px' }}>
                            <Grid container spacing={3} alignItems="stretch">
                                <Grid item xs={12} md={6} >
                                    <Card sx={{ p: 3, borderRadius: '20px' }} elevation={0}>
                                        <AreaChart areaChartData={mttrData} />
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <Card sx={{ p: 3, borderRadius: '20px' }} elevation={0}>
                                        <ReactApexChart options={mttdData.options} type="line" series={mttdData.series} height={350} />
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <Card sx={{ p: 3, borderRadius: '20px' }} elevation={0}>
                                        <MixedBarLineChart incidentsMixedChartData={incidentsMixedChartData} />
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <Card sx={{ p: 3, borderRadius: '20px' }} elevation={0}>
                                        <ReactApexChart options={slaData.options} type="line" series={slaData.series} height={350} />
                                    </Card>
                                </Grid>
                            </Grid>
                        </Card>
                    </Card>
                    <Divider sx={{ mt: 3 }} />
                </Grid>
            </Grid>

        </>
    )
}

export default ClientDashboard;