import {
  HvCard,
  HvCardContent,
  HvCardHeader,
  HvTypography,
} from "@hitachivantara/uikit-react-core";
import Header from "../Header/Header";
import { HELP_LINK } from "../../utils/constants/constants";
import "./Help.css";

const Help = () => {
  return (
    <>
      <Header />
      <HvCard bgcolor="atmo1" style={{ width: "100vw" }}>
        <HvCardHeader
          title="Help documentation links"
          className="card-header"
        />
        <HvCardContent className="help-card">
          <ul>
            <li>
              <a target="_blank" rel="noreferrer" href={HELP_LINK}>
                <HvTypography
                  variant="link"
                  style={{ paddingLeft: "5px", fontSize: "medium" }}
                >
                  Data upload
                </HvTypography>
              </a>
            </li>
          </ul>
        </HvCardContent>
      </HvCard>
      <ol>
        <li></li>
      </ol>
    </>
  );
};

export default Help;
