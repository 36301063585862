// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file */

/* & .rdrDayPassive > .rdrDayNumber * {
    color: #1d2429 !important;
} */`, "",{"version":3,"sources":["webpack://./src/common/DatePicker/DateRangePicker.css"],"names":[],"mappings":"AAAA;qEACqE;;AAErE;;GAEG","sourcesContent":["/* import 'react-date-range/dist/styles.css'; // main style file\nimport 'react-date-range/dist/theme/default.css'; // theme css file */\n\n/* & .rdrDayPassive > .rdrDayNumber * {\n    color: #1d2429 !important;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
