import { Route, Routes } from "react-router-dom";
import { PageRoutes } from "./PageRoutes";


const Router = () => {
    const renderChildren = (route) => {
        return (
            <>
                {
                    route.children.map((child, index) => {
                        // // console.log(child.element);
                        return (
                            <Route
                                path={child.path}
                                key={index}
                                element={child.element}
                            />
                        );
                    })
                }
            </>
        )
    };

    return (
        <Routes>
            {PageRoutes.map((route, index) => {
                return (
                    <Route
                        path={route.path}
                        key={index}
                        element={route.element}
                    >
                        {route.children && renderChildren(route)}
                    </Route>
                );
            })}
        </Routes>
    )
}

export default Router;
