import Logo from "../Logo/Logo";
import { useLocation, useNavigate } from "react-router-dom";
import {
  HvHeader,
  HvHeaderBrand,
  HvHeaderActions,
  HvButton,
  theme,
  HvHeaderNavigation,
  HvTypography,
  HvSwitch,
} from "@hitachivantara/uikit-react-core";
import { LogOut, Settings } from "@hitachivantara/uikit-react-icons";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useContext, useEffect, useState } from "react";
import { SettingsContext } from "../../common/SettingsContext/settingsContext";
import { useAuth } from "../../hooks/useAuth";
import { SUPERUSER, USER } from "../../utils/constants/constants";
import useAutoLogout from "../../hooks/useAutoLogout";

const Header = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selected, setSelected] = useState("1");
  const [showHacpTab, setShowHacpTab] = useState(null);
  const [automate, setAutomate] = useContext(SettingsContext);
  const [tabData, setTabData] = useState([]);
  const [anchorElement, setAnchorElement] = useState(null);
  const openSetting = Boolean(anchorElement);
  const authCtx = useAuth();
  const timer = useAutoLogout(1800);

  if (timer === 0) {
    if (automate === false || location.pathname === "/upload") {
      authCtx.logout();
    }
  }
  const NavData = [
    {
      id: "1",
      label: "HARC",
      path: "/dashboard/harc",
    },
    {
      id: "2",
      label: "HCAP",
      path: "/dashboard/hcap",
    },
  ];

  const getNavData = (showHacpTab) => {
    if (!showHacpTab) {
      return [];
    }
    return NavData;
  };

  const handleSettingClick = (event) => {
    setAnchorElement(event.currentTarget);
  };
  const handleSettingClose = () => {
    setAnchorElement(false);
  };

  useEffect(() => {
    if (window.location.href.includes("hcap")) {
      setSelected("2");
    } else {
      setSelected("1");
    }
    return () => {
      if (automateInterval) {
        clearInterval(automateInterval);
      }
    };
  }, []);

  const automateIntervalPeriod = 5000;
  let automateInterval = null;
  useEffect(() => {
    if (automate) {
      automateInterval = setInterval(() => {}, automateIntervalPeriod);
    }
  }, [automate]);

  useEffect(() => {
    setTabData(getNavData(showHacpTab));
  }, [showHacpTab]);

  return (
    <div
      style={{
        height: 100,
      }}
    >
      <div
        style={{
          minHeight: 100,
        }}
      >
        <HvHeader
          position="fixed"
          style={{ borderTop: `4px solid ${theme.colors.brand}` }}
        >
          <HvHeaderBrand
            logo={<Logo />}
            name="Hitachi Application Reliability Center"
          />
          {props.navigation && (
            <HvHeaderNavigation
              data={tabData}
              onClick={(event, selection) => {
                // console.log(selection);
                navigate(selection.path);
                setSelected(selection.id);
              }}
              selected={selected}
            />
          )}
          <HvHeaderActions>
            <HvButton
              aria-label="Open Settings panel"
              icon
              onClick={handleSettingClick}
            >
              <Settings />
            </HvButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorElement}
              open={openSetting}
              onClose={handleSettingClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {authCtx?.role === SUPERUSER && (
                <MenuItem
                  onClick={() => {
                    setShowHacpTab(!showHacpTab);
                    handleSettingClose();
                  }}
                >
                  <HvTypography>Show Tab</HvTypography>
                  <HvSwitch aria-label="TAB Control" checked={showHacpTab} />
                </MenuItem>
              )}
              <MenuItem
                onClick={() => {
                  setAutomate(!automate);
                  handleSettingClose();
                }}
              >
                <HvTypography>Show Mode</HvTypography>
                <HvSwitch
                  aria-label="Automate Selection of Matrix"
                  checked={automate}
                />
              </MenuItem>
              {authCtx?.role !== USER && (
                <MenuItem
                  onClick={() => {
                    navigate("/upload");
                  }}
                >
                  <HvTypography>Upload Data</HvTypography>
                </MenuItem>
              )}
              <MenuItem
                onClick={() => {
                  navigate("/help");
                }}
              >
                <HvTypography>Help</HvTypography>
              </MenuItem>
            </Menu>
            <HvButton
              aria-label="Logout"
              icon
              onClick={() => {
                authCtx.logout();
              }}
            >
              <LogOut />
            </HvButton>
          </HvHeaderActions>
        </HvHeader>
      </div>
    </div>
  );
};

export default Header;
