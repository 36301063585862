// material-ui
import { Box, Card, Grid, Typography } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import {
  AreaGraphWithAnnotation,
  sparklineDatav2,
  colorData,
} from "../../../../components/Charts/ChartsData";
import { theme } from "@hitachivantara/uikit-react-core";
import { dateDiffInDays } from "../../../../utils/helper";

const MetricCard = (props) => {
  const getMonths = () => {
    const months = Math.floor(
      dateDiffInDays(props.range[0].startDate, props.range[0].endDate) / 30
    );
    return months + 1;
  };
  let chartDataPoints = props.chartData
    .map((x) => ({
      x: new Date(x.date).toLocaleString(
        "default",
        getMonths() && getMonths() <= 12
          ? { month: "short", year: "2-digit" }
          : { month: "short", year: "2-digit" }
      ),
      y: Math.trunc(x.value),
    }))
    .reverse();

  for (let i = chartDataPoints.length - 1; i >= 0; i--) {
    if (
      Math.trunc(chartDataPoints[i].y) <= 0 &&
      props.name !== "changeFailureRate"
    ) {
      chartDataPoints.splice(i, 1);
    }
  }

  const colorScheme = colorData(props.name);

  const getGraphSettingOptions = () => {
    if (props.name.includes("SLO")) {
      return AreaGraphWithAnnotation;
    }

    return sparklineDatav2(colorScheme);
  };

  const setSelectedCardIndex = (event) => {
    props.setSelectedCardIndex(props.dataIndex);
  };

  return (
    <Card
      sx={{
        borderRadius: "0px",
        boxShadow:
          props.dataIndex === props.selectedCard
            ? `-1px -1px 48px 5px ${colorScheme.shadow}`
            : "none",
        border:
          props.dataIndex === props.selectedCard ? "white solid 1px" : "none",
        cursor: props.onHover ? "pointer" : "inherit",
        "&:hover": {
          boxShadow: props.onHover
            ? `-1px -1px 48px 5px ${colorScheme.shadow}`
            : "none",
          border: props.onHover ? "white solid 1px" : "none",
        },
        outline: `1px solid ${theme.colors.atmo4}`,
      }}
      onClick={setSelectedCardIndex}
    >
      {/* {props.thresholdExceeded && <div style={{display: 'flex', justifyContent: 'flex-end' }}>
          <span style={{position: 'absolute', marginTop: '-13px', marginRight: '-14px', color: theme.colors.negative}}>
          <Tooltip title={<Typography variant="subtitle2" sx={{ fontSize: '.8rem', backgroundColor: 'red' }}>{`Threshold Exceeded`}</Typography>} >                            
            <InfoIcon />                                           
          </Tooltip>
        </span>
        </div>} */}
      <Box sx={{ p: 2, backgroundColor: colorScheme.backgroundColor }}>
        <Grid>
          <Grid
            item
            container
            direction="row"
            justifyContent="space-between"
            sx={{}}
          >
            <Grid
              item
              alignSelf="flex-start"
              sx={{ maxWidth: "60%", minHeight: "80px" }}
            >
              <Typography
                variant="h5"
                sx={{
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "1.25rem",
                  color: theme.colors.brand,
                }}
              >
                {props.metricCardTitle || ""}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  mr: 1,
                  mb: 0.75,
                  fontSize: "0.675rem",
                  fontWeight: 400,
                  color: colorScheme.fontColor,
                }}
              >
                {props.metricCardSubTitle || ""}
              </Typography>
            </Grid>
            <Grid
              sx={{ display: "flex", flexDirection: "column" }}
              item
              alignSelf="flex-start"
            >
              <Grid item>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 600,
                    fontSize: "1.25rem",
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    color: colorScheme.fontColor,
                  }}
                >
                  {props.metricCardValue !== null
                    ? props.metricCardValue.prefix +
                      props.metricCardValue.value +
                      props.metricCardValue.suffix
                    : "-"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid item xs={12}>
              <Grid
                justifyContent="space-between"
                container
                spacing={1}
                sx={{ textAlign: { xs: "center", md: "left" } }}
              >
                <Grid item xs={12}>
                  <Box sx={{ justifyContent: "space-between", mx: "auto" }}>
                    <ReactApexChart
                      {...getGraphSettingOptions()}
                      series={[{ data: chartDataPoints }]}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default MetricCard;
