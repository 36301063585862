import { Navigate } from "react-router-dom";
import DashboardLayout from "../DashboardLayout/DashboardLayout";
import ClientDashboard from "../../pages/ClientDashboard/ClientDashboard";
import Dashboard from "../../pages/Dashboard/Dashboard";
import HcapDashboard from "../../pages/HcapDashboard/HcapDashboard";
import Login from "../../pages/Login/Login";
import Health from "../../pages/Health/Health";
import DataUpload from "../../pages/DataUpload/DataUpload";
import UploadClientData from "../../pages/UploadClientData/UploadClientData";
import { ProtectedLayout } from "./protected";
import { ProtectedChildLayout } from "./protectedChild";
import UnauthorizedPage from "../Unauthorized/UnauthorizedPage";
import { CONTRIBUTOR, SUPERUSER, USER } from "../../utils/constants/constants";
import Help from "../Help/Help";

export const PageRoutes = [
  {
    element: (
      <ProtectedLayout>
        <DashboardLayout />
      </ProtectedLayout>
    ),
    children: [
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/dashboard/harc",
        element: <Dashboard />,
      },
      {
        path: "/dashboard/hcap",
        element: (
          <ProtectedChildLayout allowedRoles={[SUPERUSER]}>
            <HcapDashboard />
          </ProtectedChildLayout>
        ),
      },
      // {
      //     path: '/client-dashboard/:clientName',
      //     element: <ClientDashboard />
      // },
      // {
      //     path: '/upload',
      //     element: <DataUpload />
      // }
    ],
  },
  {
    path: "/upload",
    element: (
      <ProtectedLayout>
        <ProtectedChildLayout allowedRoles={[SUPERUSER, CONTRIBUTOR]}>
          <UploadClientData />
        </ProtectedChildLayout>
      </ProtectedLayout>
    ),
  },
  {
    path: "/help",
    element: (
      <ProtectedLayout>
        <ProtectedChildLayout allowedRoles={[SUPERUSER, CONTRIBUTOR, USER]}>
          <Help />
        </ProtectedChildLayout>
      </ProtectedLayout>
    ),
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/health",
    element: <Health />,
  },
  {
    path: "/unauthorized",
    element: <UnauthorizedPage />,
  },
  {
    path: "/",
    element: <Navigate to="/login" />,
  },
];
