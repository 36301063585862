// material-ui
import {
  Box,
  Card,
  Grid,
  Typography,
  Tooltip
} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import ReactApexChart from "react-apexcharts";
import {
  AreaGraphWithAnnotation,
  sparklineDatav2,
  colorData
} from "../../../components/Charts/ChartsData";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import { theme } from '@hitachivantara/uikit-react-core';
import { useState } from "react";


const RadialOperateBoard = (props) => {

  const colorScheme = colorData(props.name);

  const displayMetricDiff = (metricCardValueDiff) => {
    if (!props.metricCardValueDiff) {
      return <>{"-"}</>;
    } else if (Math.sign(metricCardValueDiff?.value) === 1) {
      return (
        <>
          {" "}
          + {metricCardValueDiff?.prefix}
          {Math.abs(metricCardValueDiff?.value)}
          {metricCardValueDiff?.suffix}&nbsp;
          <TrendingUpIcon fontSize="inherit" />
        </>
      );
    } else if (Math.sign(metricCardValueDiff?.value) === 0) {
      return (
        <>
          {" "}
          {metricCardValueDiff?.prefix}
          {Math.abs(metricCardValueDiff?.value)}
          {metricCardValueDiff?.suffix}
        </>
      );
    } else if (Math.sign(metricCardValueDiff?.value) === -1) {
      return (
        <>
          {" "}
          - {metricCardValueDiff?.prefix}
          {Math.abs(metricCardValueDiff?.value)}
          {metricCardValueDiff?.suffix}&nbsp;
          <TrendingDownIcon fontSize="inherit" />
        </>
      );
    }
  };

  const getColorSchemaFormMetric = (value) => {
    return value === 0 || value === 1 ? colorScheme.positive : colorScheme.negative
  }

  const getColor = (metricCardValueDiff) => {
    if (metricCardValueDiff !== null) {
      return Math.sign(metricCardValueDiff?.value) === 1
        ? getColorSchemaFormMetric(1)
        : Math.sign(metricCardValueDiff?.value) === 0
        ? getColorSchemaFormMetric(0)
        : Math.sign(metricCardValueDiff?.value) === -1
        ? getColorSchemaFormMetric(-1)
        : "#004d40";
    } else return "#004d40";
  };

  const getGraphSettingOptions = () => {
    const options = {
        type: 'radialBar',
        height: 238,
        series: [100, Number((13/19)*100).toFixed(2), Number((3/19)*100).toFixed(2)],
        options: {
            chart: {
                stacked: true,
                stackType: '100%',
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {
              radialBar: {
                dataLabels: {
                  name: {
                    fontSize: '22px',
                  },
                  value: {
                    fontSize: '16px',
                  },
                  total: {
                    show: true,
                    label: 'Total Incidents',
                    formatter: function (w) {
                      // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                      return 19
                    }
                  }
                }
              }
            },
            labels: ['Total Incidents', 'Hcap Incidents', 'Acknowledged Incidents'],
            colors: [ theme.colors.secondary_60, theme.colors.secondary_80, theme.colors.secondary ],
        },
      };


    return options
  };

  const setSelectedCardIndex = (event) => {
    // props.setSelectedCardIndex(props.dataIndex)
    console.log(theme);
  }

  return (
    <Card sx={{ borderRadius: "0px", 
    boxShadow:  "none",
    border: "none",
    cursor: "inherit",
    outline: `1px solid ${theme.colors.atmo4}`,
  }} 
      onClick={setSelectedCardIndex}>
      {props.thresholdExceeded && <span style={{position: 'absolute', marginLeft: '330px', marginTop: '-10px', color: 'red'}}>
        <Tooltip title={<Typography variant="subtitle2" sx={{ fontSize: '.8rem', backgroundColor: 'red' }}>{`Threshold Exceeded`}</Typography>} >                            
          <InfoIcon />                                           
        </Tooltip>
      </span>}
      <Box sx={{ p: 2, backgroundColor: colorScheme.backgroundColor }}>
        <Grid direction="row" spacing={2}>
          <Grid
            item
            container
            direction="row"
            justifyContent="space-between"
            sx={{}}
          >
            <Grid item alignSelf="flex-start" sx={{maxWidth: '60%', minHeight: '80px' }}>
              <Typography
                variant="h5"
                sx={{
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "1.25rem",
                  color: theme.colors.brand,
                }}
              >
                {/* {props.metricCardTitle || ""} */}
                Incidents
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  mr: 1,
                  mb: 0.75,
                  fontSize: "0.675rem",
                  fontWeight: 400,
                  color: theme.colors.secondary,
                }}
              >
                {/* {props.metricCardSubTitle || ""} */}
                Number of HCAP Incidents
              </Typography>
            </Grid>
            <Grid direction="column" sx={{}} item alignSelf="flex-start">
              <Grid item>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 600,
                    fontSize: "1.25rem",
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    color: colorScheme.fontColor,
                  }}
                >
                  {/* {props.metricCardValue !== null
                    ? props?.metricCardValue?.prefix +
                      props?.metricCardValue?.value +
                      props?.metricCardValue?.suffix
                    : "-"} */}
                    19
                </Typography>
              </Grid>
              <Grid item>
                <span
                  style={{
                    color: getColor(props.metricCardValueDiff),
                    fontSize: "0.8rem",
                    fontWeight: "400",
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    textAlign: "center",
                  }}
                >
                  <>{displayMetricDiff(props.metricCardValueDiff)}</>
                </span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid item xs={12}>
              <Grid
                justifyContent="space-between"
                spacing={1}
                sx={{ textAlign: { xs: "center", md: "left" } }}
              >
                <Grid item xs={12}>
                  <Box sx={{ justifyContent: "space-between", mx: "auto" }}>
                    <ReactApexChart
                      {...getGraphSettingOptions()}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default RadialOperateBoard;
