import { Box } from "@mui/material"

const Logo = ({drawerWidth, showBorder}) => {
    return (
        <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, width: drawerWidth, py: showBorder && 2, textAlign: "center", backgroundColor: "white", borderRight: showBorder && "1px solid lightgrey" }}>
            {/* <img src="https://harc-client-data103249-dev.s3.amazonaws.com/protected/us-east-1%3A94f92474-9186-482a-ae4e-7fc51ba7adf0/Basecamp+image.jpg" alt="Hitachi Logo" width="100"></img> */}
            <img src="https://logos-download.com/wp-content/uploads/2016/04/Hitachi_logo_logotype_black.png" alt="Hitachi Logo" width="80"></img>
        </Box>
    )
}

export default Logo;