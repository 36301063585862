import { useTheme } from "@emotion/react";
import ReactApexChart from "react-apexcharts";

const AreaChart = ({ areaChartData }) => {
    const theme = useTheme();
    const options = {...areaChartData.options, colors: theme.palette.mode === 'light' ? ['#001933'] : ['#fff'], chart: { foreColor: theme.palette.mode === 'light' ? '#000000' : '#ffffff' } }
    // console.log("Options: ", {...areaChartData, options});
    return (
        <div id="chart">
            <ReactApexChart options={options} series={areaChartData.series} type="area" height={350} />
        </div>
    );
}

export default AreaChart;