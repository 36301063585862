import ReactApexChart from "react-apexcharts";

const MixedBarLineChart = ({ incidentsMixedChartData }) => {
    return (
        <ReactApexChart
            options={incidentsMixedChartData.optionsMixedChart}
            series={incidentsMixedChartData.seriesMixedChart}
            type="line"
            height={350}
        />
    )
}

export default MixedBarLineChart;