// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
    position: absolute;
    top: calc(50% - 4em);
    left: calc(50% - 4em);
    width: 3em;
    height: 3em;
    border: 10px solid rgba(0, 0, 0, 0.2);
    border-left: 10px solid #CC0000;
    border-radius: 50%;
    animation: load8 .5s infinite linear;
  }
  
  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,oBAAoB;IACpB,qBAAqB;IACrB,UAAU;IACV,WAAW;IACX,qCAAqC;IACrC,+BAA+B;IAC/B,kBAAkB;IAClB,oCAAoC;EACtC;;EAEA;IACE;MACE,uBAAuB;IACzB;IACA;MACE,yBAAyB;IAC3B;EACF","sourcesContent":[".loader {\n    position: absolute;\n    top: calc(50% - 4em);\n    left: calc(50% - 4em);\n    width: 3em;\n    height: 3em;\n    border: 10px solid rgba(0, 0, 0, 0.2);\n    border-left: 10px solid #CC0000;\n    border-radius: 50%;\n    animation: load8 .5s infinite linear;\n  }\n  \n  @keyframes load8 {\n    0% {\n      transform: rotate(0deg);\n    }\n    100% {\n      transform: rotate(360deg);\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
