// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner {
    background-color: #cc0000;
    width: 50px;
    color: white;
    width: 100%;
    height: 40px;
}

.banner-content {
    padding: 10px;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/Dashboard.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,kBAAkB;AACtB","sourcesContent":[".banner {\n    background-color: #cc0000;\n    width: 50px;\n    color: white;\n    width: 100%;\n    height: 40px;\n}\n\n.banner-content {\n    padding: 10px;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
