import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Header from '../Header/Header';
import dashboardService from "../../services/services";
import {
    theme,
  } from "@hitachivantara/uikit-react-core";

const DashboardLayout = () => {
    const [clients, setClients] = useState([]);

    useEffect(() => {
        // dashboardService.getClients().then(response => {
        //     // console.log("Clients: ", response);
        //     setClients(response.data);
        // })
    }, [])

    return (
        <Box sx={{
            backgroundColor: theme.colors.backgroundColor
        }}>
            <Header navigation />
            <Box
                sx={{
                    px: { md: 3, xs: 1 },
                    transition: 'all .2s ease-in-out',
                }}
            >
                <Outlet context={clients}/>
            </Box>
        </Box>
    )
}

export default DashboardLayout;