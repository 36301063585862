import './FileInput.css'

const FileInput = ({ importCSV }) => {
    return (
        <div style={{ margin: '10px 0px' }}>
            <input
                type="file"
                name="fileInput"
                onChange={(e) => importCSV(e)}
                accept=".csv"
            />
        </div>
    )
}

export default FileInput;