import { useTheme } from '@mui/material';
import ReactApexChart from 'react-apexcharts';

const RadialChart = ({ radialChartData }) => {
    const theme = useTheme();
    const options = {...radialChartData.options, colors: ['#1f7bc7', '#3CACB1', '#4B58A2'], chart: { foreColor: theme.palette.mode === 'light' ? '#000000' : '#ffffff' } }
    return (
        <div id="card">
            <div id="chart">
                <ReactApexChart options={options} series={radialChartData.series} type='radialBar' height={350} />
            </div>
        </div>
    )
}

export default RadialChart;