import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import SpeedIcon from "@mui/icons-material/Speed";
import SecurityIcon from "@mui/icons-material/Security";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import HandshakeIcon from "@mui/icons-material/Handshake";
import LoopIcon from "@mui/icons-material/Loop";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";

export const Icons = {
  speedIcon: <SpeedIcon fontSize="inherit" />,
  handShakeIcon: <HandshakeIcon fontSize="inherit" />,
  securityIcon: <SecurityIcon fontSize="inherit" />,
  precisionManufacturingIcon: <PrecisionManufacturingIcon fontSize="inherit" />,
  attachMoneyIcon: <AttachMoneyIcon fontSize="inherit" />,
  gppMaybeIcon: <GppMaybeIcon fontSize="inherit" />,
  loopIcon: <LoopIcon fontSize="inherit" />,
  moreTimeIcon: <MoreTimeIcon fontSize="inherit" />,
};

export const formMetricsData = [
  {
    name: "deployments",
    label: "Deployments",
  },
  {
    name: "harcScore",
    label: "HARC Score",
  },
  {
    name: "security",
    label: "Security",
  },
  {
    name: "deploymentTimeSaved",
    label: "Deployment Time Saved",
  },
  {
    name: "sloMet",
    label: "SLO Met",
  },
  {
    name: "errorBudget",
    label: "Error Budget",
  },
  {
    name: "repeatedIncidents",
    label: "Repeated Incidents",
  },
  {
    name: "cloudCost",
    label: "Cloud Cost",
  },
];

export const SUPERUSER = "SUPERUSER";
export const CONTRIBUTOR = "CONTRIBUTOR";
export const USER = "USER";

export const HELP_LINK = "https://hitachivantara.sharepoint.com/:b:/s/HARCDelivery/EfuvTKXRz4lHubpkCdkJlNwBaWdIbfKz8qDA_lt58hSleQ?e=gOZIdj";