import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Grid,
  Box,
  Stack,
  Typography,
  Divider,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  InputAdornment,
  IconButton,
  Button,
  Paper,
} from "@mui/material";
import { Formik, Form } from "formik";
import { useState } from "react";
import MainCard from "../../common/Cards/MainCard/MainCard";
import Logo from "../../components/Logo/Logo";
import dashboardService from "../../services/services";
import { useAuth } from "../../hooks/useAuth";

const AuthCardWrapper = ({ children, ...other }) => (
  <MainCard
    sx={{
      maxWidth: { xs: 400, lg: 475 },
      margin: { xs: 2.5, md: 3 },
      "& > *": {
        flexGrow: 1,
        flexBasis: "50%",
      },
    }}
    content={false}
    {...other}
  >
    <Box sx={{ p: { xs: 2, sm: 3, xl: 5 } }}>{children}</Box>
  </MainCard>
);

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const userCtx = useAuth();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (values) => {
    // await new Promise((r) => setTimeout(r, 500));
    // alert(JSON.stringify(values, null, 2));
    console.log(userCtx);

    const payload = {
      username: values.email,
      password: values.password,
    };
    await dashboardService
      .auth(payload)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data;
          userCtx.login(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Box>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        sx={{ minHeight: "100vh" }}
        component={Paper}
      >
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: "calc(100vh - 68px)" }}
          >
            <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
              <AuthCardWrapper>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item sx={{ mb: 1 }}>
                    <Logo />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      direction={"row"}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid item>
                        <Stack
                          alignItems="center"
                          justifyContent="center"
                          spacing={1}
                        >
                          <Typography
                            color="#CC0000"
                            gutterBottom
                            variant={"h5"}
                            sx={{ fontWeight: 700 }}
                          >
                            Welcome to HARC
                          </Typography>
                          <Typography
                            variant="caption"
                            fontSize="16px"
                            textAlign={"center"}
                          >
                            Enter your credentials to continue
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Formik
                      initialValues={{
                        email: "",
                        password: "",
                        submit: null,
                      }}
                      onSubmit={handleLogin}
                    >
                      {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                      }) => (
                        <Form>
                          <FormControl
                            fullWidth
                            error={Boolean(touched.email && errors.email)}
                            sx={{
                              marginTop: 1,
                              marginBottom: 1,
                              "& > label": {
                                top: 23,
                                left: 0,
                                color: "grey",
                                '&[data-shrink="false"]': {
                                  top: 5,
                                },
                              },
                              "& > div > input": {
                                padding: "30.5px 14px 11.5px !important",
                              },
                              "& legend": {
                                display: "none",
                              },
                              "& fieldset": {
                                top: 0,
                              },
                            }}
                          >
                            <InputLabel htmlFor="outlined-adornment-email-login">
                              Username
                            </InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-email-login"
                              type="text"
                              value={values.email}
                              name="email"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              label="Email Address / Username"
                              inputProps={{}}
                            />
                            {touched.email && errors.email && (
                              <FormHelperText
                                error
                                id="standard-weight-helper-text-email-login"
                              >
                                {errors.email}
                              </FormHelperText>
                            )}
                          </FormControl>

                          <FormControl
                            fullWidth
                            error={Boolean(touched.password && errors.password)}
                            sx={{
                              marginTop: 1,
                              marginBottom: 1,
                              "& > label": {
                                top: 23,
                                left: 0,
                                color: "grey",
                                '&[data-shrink="false"]': {
                                  top: 5,
                                },
                              },
                              "& > div > input": {
                                padding: "30.5px 14px 11.5px !important",
                              },
                              "& legend": {
                                display: "none",
                              },
                              "& fieldset": {
                                top: 0,
                              },
                            }}
                          >
                            <InputLabel htmlFor="outlined-adornment-password-login">
                              Password
                            </InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-password-login"
                              type={showPassword ? "text" : "password"}
                              value={values.password}
                              name="password"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    edge="end"
                                    size="large"
                                  >
                                    {showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              label="Password"
                              inputProps={{}}
                            />
                            {touched.password && errors.password && (
                              <FormHelperText
                                error
                                id="standard-weight-helper-text-password-login"
                              >
                                {errors.password}
                              </FormHelperText>
                            )}
                          </FormControl>
                          {errors.submit && (
                            <Box sx={{ mt: 3 }}>
                              <FormHelperText error>
                                {errors.submit}
                              </FormHelperText>
                            </Box>
                          )}
                          <Grid item xs={12} sx={{ my: 2 }}>
                            <Divider />
                          </Grid>

                          <Box sx={{ mt: 2 }}>
                            <Button
                              disableElevation
                              disabled={isSubmitting}
                              fullWidth
                              size="large"
                              type="submit"
                              variant="contained"
                              sx={{
                                backgroundColor: "#CC0000",
                                color: "white",
                                py: 2,
                                "&:hover": {
                                  backgroundColor: "#CC0000",
                                  color: "white",
                                },
                              }}
                            >
                              Sign in
                            </Button>
                          </Box>
                        </Form>
                      )}
                    </Formik>
                  </Grid>
                </Grid>
              </AuthCardWrapper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Login;
