import "./UnauthorizedPage.css";

const UnauthorizedPage = () => {
  return (
    <div className="unauthorized-container">
      <h1 style={{ fontSize: "xxx-large" }}>Access Denied - 401</h1>
      <h2 style={{ fontSize: "xx-large", margin: "20px" }}>
        You do not have permission to view this page
      </h2>
    </div>
  );
};
export default UnauthorizedPage;
