// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label {
    text-align: left;
    font-size: 19px;
    font-family: 'Open Sans';
    letter-spacing: 0.38px;
    color: #595959;
    opacity: 1;
    padding-bottom: 10px;
}

input[type=file] {
  width: 100%;
  font-style: italic;
  border-bottom: 1px solid #B2B2B2;
}

input[type=file]::file-selector-button {
  font-weight: bold;
  background: #eceaea 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  opacity: 1;
  padding: 10px 22px;
  border-radius: 5px;
  color: #414141;
  margin-bottom: 0px;
}`, "",{"version":3,"sources":["webpack://./src/components/FileInput/FileInput.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,eAAe;IACf,wBAAwB;IACxB,sBAAsB;IACtB,cAAc;IACd,UAAU;IACV,oBAAoB;AACxB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,gCAAgC;AAClC;;AAEA;EACE,iBAAiB;EACjB,+CAA+C;EAC/C,yBAAyB;EACzB,UAAU;EACV,kBAAkB;EAClB,kBAAkB;EAClB,cAAc;EACd,kBAAkB;AACpB","sourcesContent":[".label {\n    text-align: left;\n    font-size: 19px;\n    font-family: 'Open Sans';\n    letter-spacing: 0.38px;\n    color: #595959;\n    opacity: 1;\n    padding-bottom: 10px;\n}\n\ninput[type=file] {\n  width: 100%;\n  font-style: italic;\n  border-bottom: 1px solid #B2B2B2;\n}\n\ninput[type=file]::file-selector-button {\n  font-weight: bold;\n  background: #eceaea 0% 0% no-repeat padding-box;\n  border: 1px solid #B2B2B2;\n  opacity: 1;\n  padding: 10px 22px;\n  border-radius: 5px;\n  color: #414141;\n  margin-bottom: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
