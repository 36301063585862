/* eslint-disable no-unused-vars */
import { useEffect, useState, Fragment, useContext } from "react";
import dashboardService from "../../services/services";
import MetricCard from "../../common/Cards/v3/MetricCard/MetricCard";
import { customranges } from "../../common/DatePicker/DateRangePicker";
import CompareChart from "../../common/Cards/CompareChart/CompareChart";
import { dateDiffInDays } from "../../utils/helper";
import { addDays } from "date-fns";
import CompareTable from "../../common/Cards/CompareTable/CompareTable";
import { HvGrid, HvBox, theme } from "@hitachivantara/uikit-react-core";
import { DatePickerRange } from "../../common/hvDatePicker/hvDatePicker";
import { SettingsContext } from "../../common/SettingsContext/settingsContext";
import { useRef } from "react";
import { useAuth } from "../../hooks/useAuth";
import "./Dashboard.css";

const Dashboard = () => {
  const [dashboardMetrics, setDashboardMetrics] = useState([]);
  const [prevPeriodDashboardMetrics, setPrevPeriodDashboardMetrics] = useState(
    []
  );

  const [selectedCard, setSelectedCard] = useState(-1);
  const selectedCardRef = useRef(selectedCard);
  selectedCardRef.current = selectedCard;

  const [range, setRange] = useState([customranges[3].range()]);

  const [automate, setAutomate] = useContext(SettingsContext);
  const automateRef = useRef(automate);
  automateRef.current = automate;

  const [nonHcap, setNonHcap] = useState([]);
  const [isSafari, setIsSafari] = useState(false);
  let nonHcapRef = useRef(nonHcap);
  nonHcapRef.current = nonHcap;

  const authCtx = useAuth();

  const getMetricDataForTimePeriod = () => {
    const diffDays = dateDiffInDays(range[0].endDate, range[0].startDate);
    const diffEndDate = addDays(new Date(range[0].startDate), -1);
    const diffStartDate = addDays(new Date(diffEndDate), diffDays);
    dashboardService
      .getDashboardMetricsv2({ startDate: diffStartDate, endDate: diffEndDate })
      .then((response) => {
        const data = response.data.sort((a, b) => a.viewOrder - b.viewOrder);
        setPrevPeriodDashboardMetrics(data);
      })
      .catch((error) => {
        if (error?.response?.status === 403) {
          authCtx.logout();
        }
      });
  };

  const onSetRange = (range) => {
    setRange(range);
  };

  const getDashboardMetricsData = (rangeData) => {
    dashboardService
      .getDashboardMetricsv2(rangeData)
      .then((response) => {
        const sortdata = response.data.sort(
          (a, b) => a.viewOrder - b.viewOrder
        );
        const data = sortdata.map((m, i) => {
          m.index = i;
          return m;
        });
        const nonHcapData = data.filter(
          (metric) => !metric.name.toLowerCase().includes("hcap")
        );
        setNonHcap(nonHcapData);
        setDashboardMetrics(data);
      })
      .catch((error) => {
        if (error?.response?.status === 403) {
          authCtx.logout();
        }
      });
  };

  const automateIntervalPeriod = 5000;
  function automateSelection() {
    console.log(automateRef.current);
    if (automateRef.current && nonHcapRef.current.length > 0) {
      const selected =
        selectedCardRef.current >= 0 ? selectedCardRef.current + 1 : 0;
      const selectNext = selected % nonHcapRef.current.length;
      setSelectedCard(selectNext);
    }
  }

  let automateInterval = null;
  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf("safari") != -1) {
      if (userAgent.indexOf("chrome") > -1) {
        setIsSafari(false)
      } else {
        setIsSafari(true)
      }
    }

    try {
      // console.log('Dashboard mounted')
      // getDashboardMetricsData(range[0]);
      getMetricDataForTimePeriod();
      // start Interval
      automateInterval = setInterval(automateSelection, automateIntervalPeriod);
      return () => {
        if (automateInterval) {
          clearInterval(automateInterval);
        }
      };
    } catch (error) {
      if (error?.response?.status === 403) {
        authCtx.logout();
      }
    }
  }, []);

  useEffect(() => {
    try {
      getDashboardMetricsData(range[0]);
      if (selectedCard > -1) {
        getMetricDataForTimePeriod();
      }
    } catch (error) {
      if (error?.response?.status === 403) {
        authCtx.logout();
      }
    }
  }, [range]);

  const setSelectedCardIndex = (dataIndex) => {
    if (selectedCard === dataIndex) {
      setSelectedCard(-1);
    } else {
      setSelectedCard(dataIndex);
    }
  };

  const showCompareAtIndex = (index, selectedCardData, metrics) => {
    let showAtIndex = 3;
    if (selectedCardData <= 3) {
      showAtIndex = 3;
    } else if (selectedCardData <= 7 && selectedCardData > 3) {
      showAtIndex = metrics.length - 1 < 7 ? metrics.length - 1 : 7;
    } else if (selectedCardData <= 11 && selectedCardData > 7) {
      showAtIndex = metrics.length - 1 < 11 ? metrics.length - 1 : 11;
    }

    if (showAtIndex > nonHcap.length) {
      return selectedCard;
    }

    return showAtIndex;
  };

  const renderNonHcapmetrics = () => {
    return nonHcap.map((data, index) => {
      return (
        <Fragment key={index + data.name}>
          <HvGrid
            key={index + data.name}
            item
            xs={12}
            sm={12}
            md={6}
            xl={4}
            sx={{ maxWidth: "25% !important" }}
          >
            <MetricCard
              {...data}
              dataIndex={index}
              selectedCard={selectedCard}
              setSelectedCardIndex={setSelectedCardIndex}
              onHover
              range={range}
            />
          </HvGrid>
          {nonHcap.length > 0 &&
            prevPeriodDashboardMetrics.length > 0 &&
            selectedCard > -1 &&
            showCompareAtIndex(index, selectedCard, nonHcap) === index && (
              <HvGrid
                item
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  minWidth: "100% !important",
                }}
              >
                <HvGrid item sx={{ flex: 1 }}>
                  <CompareChart
                    key={selectedCard + "compare"}
                    selectedCard
                    {...dashboardMetrics[selectedCard]}
                    range={range}
                    prevPeriodDashboardMetrics={
                      prevPeriodDashboardMetrics[selectedCard]
                    }
                  ></CompareChart>
                </HvGrid>
                <HvGrid
                  item
                  sx={{ marginLeft: "1.5rem", minWidth: "33% !important" }}
                >
                  <CompareTable
                    key={selectedCard + "compareTable"}
                    {...dashboardMetrics[selectedCard]}
                    range={range}
                    prevPeriodDashboardMetrics={
                      prevPeriodDashboardMetrics[selectedCard]
                    }
                  />
                </HvGrid>
              </HvGrid>
            )}
        </Fragment>
      );
    });
  };

  return (
    <div style={{ backgroundColor: theme.colors.backgroundColor }}>
      {isSafari && <div className="banner">
        <div className="banner-content">
          This website is best viewed on Google Chrome or Microsoft Edge
          browser, we currently do not support Safari, Opera and Internet
          Explorer
        </div>
      </div>}
      <HvBox sx={{ paddingTop: "2rem" }}>
        <HvBox sx={{ marginBottom: "2rem" }}>
          <HvGrid sx={{ marginLeft: "80%", maxWidth: "20%" }}>
            <DatePickerRange onRangeSelect={onSetRange} range={range} />
          </HvGrid>
        </HvBox>
        <HvBox>
          <HvGrid
            container
            spacing={3}
            alignItems="stretch"
            sx={{ marginBottom: "2rem" }}
          >
            {renderNonHcapmetrics()}
          </HvGrid>
        </HvBox>
        <br />
      </HvBox>
    </div>
  );
};

export default Dashboard;
