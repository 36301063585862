import { useState } from "react";
import { HvDatePicker, HvListItem, HvListContainer } from "@hitachivantara/uikit-react-core";
import { addMonths, addYears, startOfDay, endOfDay, isSameDay, startOfMonth } from 'date-fns';
import set from "date-fns/set";
import { theme } from '@hitachivantara/uikit-react-core'

export const customranges = [{
        label: "This Month",
        range: () => ({
            startDate: startOfMonth(new Date()),
            endDate: startOfDay(new Date()),
            key: "thismonth",
        }),
    },
    {
        label: "Last Month",
        range: () => ({
            startDate: startOfMonth(new Date()),
            endDate: startOfDay(new Date()),
            key: "lastmonth",
        }),
    },
    {
        label: "Three Month",
        range: () => ({
            startDate: startOfMonth(addMonths(new Date(), -2)),
            endDate: startOfDay(new Date()),
            key: "threemonth",
        }),
    },
    {
        label: 'Six Month',
        range: () => ({
            startDate: startOfMonth(addMonths(new Date(), -5)),
            endDate: startOfDay(new Date()),
            key: "sixmonth",
        }),
    },
    {
        label: "One Year",
        range: () => ({
        startDate: addYears(new Date(), -1),
        endDate: new Date(),
        key: "oneyear",
        }),
    },
    {
        label: "Two Year",
        range: () => ({
        startDate: addYears(new Date(), -2),
        endDate: new Date(),
        key: "twoyear",
        }),
    },
    // {
    //     label: "All Time",
    //     range: () => ({
    //     startDate: null,
    //     endDate: new Date()
    //     }),
    // },
];

export const DatePickerRange = (props) => {
    const [state, setState] = useState([
        customranges[3].range()
      ]);

    const onRangeSelect = (item) => {
        setState([item]);
        props.onRangeSelect([item]);
    }

    const listView = () => {
        return (
          <HvListContainer
            interactive
            role="menu"
            style={{ minWidth: 160, padding: "40px 20px" }}
          >
            {customranges.map((item) => {
                const itemRange = item.range();
                // // console.log(state.key, itemRange.key);
                return (
                    <HvListItem key={itemRange.key} onClick={(event) => {
                        setState([itemRange]);
                    }} role="menuitem" sx={{"&:hover": {
                       backgroundColor: theme.colors.primary_80
                      }}}>
                        {item.label}
                    </HvListItem>
                );
            })}
          </HvListContainer>
        );
    }

    return (
       <div>
            <HvDatePicker
                aria-label="Date"
                endValue={state[0].endDate}
                id="hvDatePicker"
                onCancel={() => {
                    setState(props.range);
                }}
                onChange={(date, endDate) => {
                    const rangeSelected = {
                        startDate: new Date(date),
                        endDate: new Date(endDate)
                    }
                    onRangeSelect(rangeSelected)
                }}
                placeholder="Select date"
                rangeMode
                startAdornment={listView()}
                startValue={state[0].startDate}
            />
       </div>
    );
}