import { useEffect, useState, Fragment } from "react";
import dashboardService from "../../services/services";
import MetricCard from "../../common/Cards/v3/MetricCard/MetricCard";
import { customranges } from "../../common/DatePicker/DateRangePicker";
import CompareChart from "../../common/Cards/CompareChart/CompareChart";
import { dateDiffInDays } from "../../utils/helper";
import { addDays } from "date-fns";
import CompareTable from "../../common/Cards/CompareTable/CompareTable";
import {
    HvGrid,
    HvBox,
    theme,
  } from "@hitachivantara/uikit-react-core";
import RadialOperateBoard from "../../common/Cards/OperateBoard/RadialOperateBoard";
import { DatePickerRange } from "../../common/hvDatePicker/hvDatePicker";
import { useAuth } from "../../hooks/useAuth";

const HcapDashboard = () => {

    const [dashboardMetrics, setDashboardMetrics] = useState([]);
    const [prevPeriodDashboardMetrics, setPrevPeriodDashboardMetrics] = useState([]);
    const [selectedCard, setSelectedCard] = useState(-1);
    const [hcapSelectedCard, setHcapSelectedCard] = useState(-1);
    const [range, setRange] = useState([customranges[3].range()]);
    const authCtx = useAuth();

    const getMetricDataForTimePeriod = () => {
        const diffDays = dateDiffInDays(range[0].endDate, range[0].startDate);
        const diffEndDate = addDays(new Date(range[0].startDate), -1);
        const diffStartDate = addDays(new Date(diffEndDate), (diffDays));
        dashboardService.getDashboardMetricsv2({startDate: diffStartDate, endDate: diffEndDate}).then(response => {
            const data = response.data.sort((a,b) => (a.viewOrder - b.viewOrder));
            setPrevPeriodDashboardMetrics(data);
        }).catch(error => {
            if (error?.response?.status === 403) {
              authCtx.logout();
            }
          })
    }

    const onSetRange = (range) => {
        setRange(range);
    }

    const getDashboardMetricsData = (rangeData) => {
        dashboardService.getDashboardMetricsv2(rangeData).then(response => {
            const sortdata = response.data.sort((a,b) => (a.viewOrder - b.viewOrder));
            const data = sortdata.map((m, i) => {
                m.index = i;
                return m;
            });
            setDashboardMetrics(data);
        }).catch(error => {
            if (error?.response?.status === 403) {
              authCtx.logout();
            }
          });
    }

    useEffect(() => {
        getDashboardMetricsData(range[0]);
        getMetricDataForTimePeriod();
    }, [])

    useEffect(() => {
        getDashboardMetricsData(range[0]);
        if(selectedCard > -1) {
            getMetricDataForTimePeriod();
        }
    }, [range])


    const setSelectedCardIndex = (dataIndex) => {
        if (selectedCard === dataIndex) { 
            setSelectedCard(-1);
        } else {
            setSelectedCard(dataIndex);
        }
    }

    const setHcapSelectedCardIndex = (dataIndex) => {
        if (selectedCard === dataIndex) { 
            setHcapSelectedCard(-1);
        } else {
            setHcapSelectedCard(dataIndex);
        }
    }

    const showCompareAtIndex = (index, selectedCardData, metrics) => {
        let showAtIndex = 3
        if (selectedCardData <= 3) {
            showAtIndex = 3
        } else if(selectedCardData <= 7 && selectedCardData > 3) {
            showAtIndex = metrics.length - 1 < 7  ? metrics.length - 1 : 7;
        } else if (selectedCardData <= 11 && selectedCardData > 7) {
            showAtIndex = metrics.length - 1 < 11  ? metrics.length - 1 : 11;
        }

        return showAtIndex;
    }




    const renderHcapmetrics = () => {
        const hcap = dashboardMetrics.filter((metric) => metric.name.toLowerCase().includes('hcap'));
        return hcap.map((data, index) => {
            return (
                <Fragment key={index + data.name}>
                    <HvGrid key={index + data.name} item xs={12} sm={12} md={6} xl={4} sx={{maxWidth: '25% !important'}}>
                        <MetricCard {...data} dataIndex={index}  selectedCard={-1} setSelectedCardIndex={setHcapSelectedCardIndex} onHover={false} range={range}/>
                    </HvGrid>
                    {hcap.length > 0 && prevPeriodDashboardMetrics.length > 0 
                        && (hcapSelectedCard > -1) && showCompareAtIndex(index, hcapSelectedCard, hcap) === index
                        && 
                            <HvGrid item sx={{display: 'flex', flexDirection: 'row', justifyContent: "space-between", minWidth: '100% !important'}}>
                                <HvGrid item sx={{flex: 1}}>
                                    <CompareChart key={hcapSelectedCard+'compare'} hcapSelectedCard {...hcap[hcapSelectedCard]} range={range} prevPeriodDashboardMetrics={prevPeriodDashboardMetrics[data.index]}></CompareChart>
                                </HvGrid>
                                <HvGrid item sx={{marginLeft: '1.5rem', minWidth: '33% !important'}}>
                                    <CompareTable key={hcapSelectedCard+'compareTable'} {...hcap[hcapSelectedCard]} range={range} prevPeriodDashboardMetrics={prevPeriodDashboardMetrics[data.index]} />
                                </HvGrid>
                            </HvGrid>
                    }
                 </Fragment>
            )
        })
    }


    return (
        <div style={{ backgroundColor: theme.colors.backgroundColor }}>
            <HvBox sx={{ paddingTop: '2rem' }}>
                <HvBox sx={{ marginBottom: '2rem' }}>
                    <HvGrid sx={{marginLeft:'80%', maxWidth: '20%' }}>
                        <DatePickerRange onRangeSelect={onSetRange} range={range} />
                    </HvGrid>
                </HvBox>
                <HvBox>
                    <HvGrid container spacing={3} alignItems='stretch' sx={{marginTop: '1rem'}}>
                        {renderHcapmetrics()}
                        <HvGrid key={"test1"} item sx={{maxWidth: '25% !important'}}>
                            <RadialOperateBoard name="comments"></RadialOperateBoard>
                        </HvGrid>
                    </HvGrid>
                </HvBox>
                <br />
            </HvBox>
        </div>
    )
}

export default HcapDashboard;