import {
  theme,
  HvGrid,
  HvGlobalActions,
  HvInput,
  HvButton,
  HvDropdown,
  HvFileUploader,
  HvSnackbar,
  HvTypography,
  HvTableContainer,
  HvTableBody,
  HvTableHeader,
  HvTableRow,
  HvTableCell,
  HvTableHead,
  HvTable,
} from "@hitachivantara/uikit-react-core";
import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import Header from "../../components/Header/Header";
import dashboardService from "../../services/services";
import { Download, Preview } from "@mui/icons-material";
import { useAuth } from "../../hooks/useAuth";
import fileUploadTypes from "../../utils/constants/fileUploadTypes.json";

const UploadClientData = (props) => {
  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState(null);
  const [variant, setVariant] = useState("default");

  const [dataTypes, setDataTypes] = useState([]);

  const [accountKey, setAccountKey] = useState("");
  const [clientErr, setClientErr] = useState({ status: "valid", message: "" });

  const [dataType, setDataType] = useState("");
  const [dataTypeErr, setDataTypeErr] = useState({
    status: "valid",
    message: "",
  });

  const [list, setList] = useState([]);
  const [uploadedFileError, setuploadedFileError] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [encryptedBlob, setEncryptedBlob] = useState(null);
  const [encryptname, setEncryptname] = useState("");
  const authCtx = useAuth();

  useEffect(() => {

    let dataTypesData = fileUploadTypes;

    dataTypesData = dataTypesData.map((data) => ({
      ...data,
    }));
    setDataTypes(dataTypesData);
  }, []);

  function readfile(file) {
    return new Promise((resolve, reject) => {
      var fr = new FileReader();
      fr.onload = () => {
        resolve(fr.result);
      };
      fr.readAsArrayBuffer(file);
    });
  }

  async function addFile(file, setList) {
    const newFile = file;
    const hasFailed = file.status === "fail";
    if (!hasFailed) {
      // handleUpload(file);
      setuploadedFileError(false);
      // showHideFileDrop('none');
      setList((previousList) => [newFile, ...previousList]);
    } else {
      handleOpenSnackBar(
        "error",
        file?.errorMessage ? file.errorMessage : "File Upload Failed"
      );
      setuploadedFileError(true);
    }

    var plaintextbytes = await readfile(newFile).catch(function (err) {
      console.error(err);
    });
    var plaintextbytes = new Uint8Array(plaintextbytes);

    var pbkdf2iterations = 10000;
    var passphrasebytes = new TextEncoder("utf-8").encode("12345678");
    var pbkdf2salt = window.crypto.getRandomValues(new Uint8Array(8));

    var passphrasekey = await window.crypto.subtle
      .importKey("raw", passphrasebytes, { name: "PBKDF2" }, false, [
        "deriveBits",
      ])
      .catch(function (err) {
        console.error(err);
      });
    console.log("passphrasekey imported");

    var pbkdf2bytes = await window.crypto.subtle
      .deriveBits(
        {
          name: "PBKDF2",
          salt: pbkdf2salt,
          iterations: pbkdf2iterations,
          hash: "SHA-256",
        },
        passphrasekey,
        384
      )
      .catch(function (err) {
        console.error(err);
      });
    console.log("pbkdf2bytes derived");
    pbkdf2bytes = new Uint8Array(pbkdf2bytes);

    var keybytes = pbkdf2bytes.slice(0, 32);
    var ivbytes = pbkdf2bytes.slice(32);

    var key = await window.crypto.subtle
      .importKey("raw", keybytes, { name: "AES-CBC", length: 256 }, false, [
        "encrypt",
      ])
      .catch(function (err) {
        console.error(err);
      });
    console.log("key imported");

    var cipherbytes = await window.crypto.subtle
      .encrypt({ name: "AES-CBC", iv: ivbytes }, key, plaintextbytes)
      .catch(function (err) {
        console.error(err);
      });

    console.log("plaintext encrypted");
    cipherbytes = new Uint8Array(cipherbytes);

    var resultbytes = new Uint8Array(cipherbytes.length + 16);
    resultbytes.set(new TextEncoder("utf-8").encode("Salted__"));
    resultbytes.set(pbkdf2salt, 8);
    resultbytes.set(cipherbytes, 16);

    var blob = new Blob([resultbytes], { type: "application/download" });
    const uploadBlob = new File([blob], `${accountKey}-${file.name}`);
    setEncryptedBlob(uploadBlob);
    // var blobUrl=URL.createObjectURL(blob);
    // setEncryptedBlob(blobUrl);
    // setEncryptname(file.name);
    // // aEncsavefile.href=blobUrl;
    // // aEncsavefile.download=objFile.name + '.enc';
  }

  const removeFile = (fileToRemove, setList) => {
    setList((previousList) =>
      previousList.filter((file) => file !== fileToRemove)
    );
  };

  const handleOpenSnackBar = (variant, message) => {
    setVariant(variant);
    setSnackBarMessage(message);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") return;
    setOpen(false);
  };

  const resetClientErr = () => {
    setClientErr({ status: "valid", message: "" });
  };

  const resetDataTypeErr = () => {
    setDataTypeErr({ status: "valid", message: "" });
  };

  const resetFileErr = () => {
    setuploadedFileError(false);
  };

  const resetAllField = () => {
    resetClientErr();
    setAccountKey("");

    resetDataTypeErr();
    setDataType("");
    const dataTypesData = dataTypes.map((data) => ({
      ...data,
      selecetd: false,
    }));
    setDataTypes(dataTypesData);

    setList([]);
    setuploadedFileError(false);
  };

  const onUploadClick = () => {
    let err = false;

    if (!accountKey) {
      setClientErr({
        status: "invalid",
        message: "Please select Account Key.",
      });
      err = true;
    }
    if (!dataType) {
      setDataTypeErr({ status: "invalid", message: "Please select Datatype." });
      err = true;
    }
    if (list.length <= 0) {
      setuploadedFileError(true);
      err = true;
    }

    if (err) {
      return;
    }

    setUploading(true);
    // validate API Key
    dashboardService
      .validateAPIKey({
        accountKey: accountKey,
      })
      .then((data) => {
        console.log(data);
        if (data.status === 200 && data.data.isValid === true) {
          const formData = new FormData();
          formData.append("accountKey", accountKey);
          formData.append("dataType", dataType.value);
          formData.append("file", encryptedBlob);

          // for (let pair of formData.entries()) {
          //     console.log(pair[0], pair[1]);
          // }
          dashboardService
            .postClientsData(formData)
            .then((data) => {
              console.log(data);
              setUploading(false);
              if (data.status === 200) {
                handleOpenSnackBar("success", "File Uploaded Successfully!");
                resetAllField();
              } else {
                handleOpenSnackBar(
                  "error",
                  `File Upload Failed. ${data.status}`
                );
              }
            })
            .catch((err) => {
              if (err?.response?.status === 403) {
                authCtx.logout();
              }
              setUploading(false);
              console.log(err);
              handleOpenSnackBar(
                "error",
                `File Upload Failed. ${err?.response?.data?.message}`
              );
            });
        } else {
          setUploading(false);
          handleOpenSnackBar("error", `Account Key Validation Failed`);
        }
      })
      .catch((e) => {
        if (e?.response?.status === 403) {
          authCtx.logout();
        }
        setUploading(false);
        handleOpenSnackBar("error", `Account Key Validation Failed`);
        console.log(e);
        return;
      });
  };

  const templateView = () => {
    return (
      <HvTableContainer style={{ minWidth: "400px" }}>
        <HvTable variant="default">
          <HvTableHead>
            <HvTableRow>
              <HvTableHeader>DataType</HvTableHeader>
              <HvTableHeader>View</HvTableHeader>
              <HvTableHeader>Download</HvTableHeader>
            </HvTableRow>
          </HvTableHead>
          <HvTableBody>
            {dataTypes.map((type, i) => {
              if (i < dataTypes.length - 1) {
                return (
                  <HvTableRow key={i + type.label}>
                    <HvTableCell>{type.label}</HvTableCell>
                    <HvTableCell>
                      <a target="_blank" rel="noreferrer" href={type.viewLink}>
                        <HvTypography
                          variant="body"
                          style={{ paddingLeft: "5px" }}
                        >
                          <Preview />
                        </HvTypography>
                      </a>
                    </HvTableCell>
                    <HvTableCell>
                      <a rel="noreferrer" href={type.downloadLink} download>
                        <HvTypography
                          variant="body"
                          style={{ paddingLeft: "20px" }}
                        >
                          <Download />
                        </HvTypography>
                      </a>
                    </HvTableCell>
                  </HvTableRow>
                );
              }
              return null;
            })}
          </HvTableBody>
        </HvTable>
      </HvTableContainer>
    );
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.colors.backgroundColor,
      }}
    >
      <Header />
      <Box
        sx={{
          px: { md: 3, xs: 1 },
          transition: "all .2s ease-in-out",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <HvGrid>
          <form>
            <HvGrid container sx={{ display: "flex", alignSelf: "center" }}>
              <HvGrid item xs={12}>
                <HvGlobalActions title="Upload Data" variant="section" />
              </HvGrid>
              <HvGrid item md={6} xs={12}>
                <HvInput
                  id="client"
                  label="Account Key"
                  onChange={(event, value) => {
                    console.log(value);
                    setAccountKey(value);
                    setClientErr({ status: "valid", message: "" });
                  }}
                  required
                  status={clientErr.status}
                  statusMessage={clientErr.message}
                  value={accountKey}
                />
              </HvGrid>

              <HvGrid item md={6} xs={12}>
                <HvDropdown
                  id="datatype"
                  label="DataType"
                  onChange={(value) => {
                    console.log(value);
                    setDataType(value);
                    setDataTypeErr({ status: "valid", message: "" });
                  }}
                  required
                  status={dataTypeErr.status}
                  statusMessage={dataTypeErr.message}
                  values={dataTypes}
                />
              </HvGrid>
              <HvGrid item sx={{ minWidth: "100%" }}>
                <HvFileUploader
                  // acceptedFiles={[
                  //     'csv'
                  // ]}
                  fileList={list}
                  labels={{
                    dropzone: "Upload your file, ",
                  }}
                  maxFileSize={25 * 1000 ** 2}
                  onFilesAdded={(newFiles) => {
                    newFiles.forEach((newFile) => addFile(newFile, setList));
                  }}
                  onFileRemoved={(removedFile) => {
                    removeFile(removedFile, setList);
                  }}
                  multiple={false}
                  disabled={list.length === 1}
                />
                {uploadedFileError && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: "10px",
                    }}
                  >
                    <div
                      className="HvWarningText-defaultIcon HvIconBase-root HvIconBase-s"
                      name="Fail"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                        height="16"
                        width="16"
                        focusable="false"
                      >
                        <path
                          fill="#C62828"
                          className="color0"
                          d="M7.5 4.001h1v6h-1zm0 8h1v-1h-1zM16 8a8 8 0 10-8 8 8 8 0 008-8zm-1 0a7 7 0 11-7-7 7 7 0 017 7z"
                        ></path>
                      </svg>
                    </div>
                    <p
                      id="hvdropdown42-error"
                      style={{
                        color: theme.colors.negative,
                        marginLeft: "10px",
                      }}
                      aria-live="polite"
                      aria-atomic="true"
                      aria-relevant="additions text"
                    >
                      Please Select File to Upload.
                    </p>
                  </div>
                )}
              </HvGrid>
              <HvGrid
                item
                sx={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <HvButton
                  id="cancel"
                  variant="primaryGhost"
                  type="reset"
                  onClick={() => {
                    resetAllField();
                  }}
                >
                  Clear
                </HvButton>
                <HvButton
                  variant="primaryGhost"
                  onClick={onUploadClick}
                  disabled={uploading}
                >
                  Upload
                </HvButton>
              </HvGrid>
            </HvGrid>
          </form>
        </HvGrid>
        <HvGrid
          sx={{ px: 5, display: "flex", flex: 1, justifyContent: "center" }}
        >
          <HvGrid>
            <HvGrid sx={{ alignSelf: "center" }}>
              <HvGlobalActions title="Templates Formats" variant="section" />
              <HvTypography
                style={{
                  paddingBottom: "5px",
                  paddingTop: "10px",
                  paddingLeft: "5px",
                }}
                variant="caption1"
              >
                Use the below template formats to upload the data for clients.
              </HvTypography>
            </HvGrid>
            {templateView()}
          </HvGrid>
        </HvGrid>
        <HvSnackbar
          open={open}
          showIcon
          label={snackBarMessage}
          variant={variant}
          onClose={handleClose}
        />
      </Box>
    </Box>
  );
};

export default UploadClientData;
