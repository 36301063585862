import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Grid, Box, Stack, Typography, Divider, FormControl, InputLabel, OutlinedInput, FormHelperText, InputAdornment, IconButton, Button, Paper } from "@mui/material";
import { Formik, Form } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import MainCard from "../../common/Cards/MainCard/MainCard";
import Logo from "../../components/Logo/Logo";
import dashboardService from '../../services/services';
import { useAuth } from "../../hooks/useAuth";

const AuthCardWrapper = ({ children, ...other }) => (
    <MainCard
        sx={{
            maxWidth: { xs: 400, lg: 475 },
            margin: { xs: 2.5, md: 3 },
            '& > *': {
                flexGrow: 1,
                flexBasis: '50%'
            }
        }}
        content={false}
        {...other}
    >
        <Box sx={{ p: { xs: 2, sm: 3, xl: 5 } }}>{children}</Box>
    </MainCard>
);

const Login = () => {

    const userCtx = useAuth();

    const handleLogin = async (values) => {
        console.log(userCtx);
    }

    return (
        <p>Up</p>
    )
}

export default Login;