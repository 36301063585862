import axios from "axios";
import { BASE_URL } from "../utils/constants/apis";
const apiBaseUrl = BASE_URL + "/dashboard/api";
const filterBaseUrl = BASE_URL + "/dashboard/api";

const auth = (payload) => {
  return axios.post(`${apiBaseUrl}/v1/auth/signin`, payload, {
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": "harc",
    },
  });
};

const getDashboardMetricsv2 = (data) => {
  let token = window.localStorage.getItem("userToken");
  token = JSON.parse(token);
  const payload = {
    startDate: data.startDate,
    endDate: data.endDate,
  };
  // const payload = {
  //     "startDate": "2023-04-01",
  //     "endDate": "2023-11-01"
  // }
  return axios.post(`${filterBaseUrl}/v1/metrics/all`, payload, {
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": "harc",
      Authorization: `Bearer ${token}`,
    },
  });
};

const validateAPIKey = (payload) => {
  let token = window.localStorage.getItem("userToken");
  token = JSON.parse(token);
  return axios.post(`${apiBaseUrl}/v1/validate`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const postClientsData = (payload) => {
  let token = window.localStorage.getItem("userToken");
  token = JSON.parse(token);
  return axios.post(`${apiBaseUrl}/v1/upload`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const services = {
  getDashboardMetricsv2,
  postClientsData,
  auth,
  validateAPIKey,
};
export default services;
