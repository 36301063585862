import { useTheme } from "@mui/material";
import ReactApexChart from "react-apexcharts";

// const tempData = [
//     [1327359600000, 50.95],
//     [1327446000000, 51.34],
//     [1327532400000, 51.18],
//     [1327618800000, 51.05],
//     [1327878000000, 51],
//     [1327964400000, 50.95],
//     [1328050800000, 51.24],
//     [1328137200000, 51.29],
//     [1328223600000, 51.85],
// ];


const ColumnChart = ({ chartData }) => {
    const theme = useTheme();
    const options = {...chartData.options, colors: theme.palette.mode === 'light' ? ['#CC0000', '#001933', 'grey'] : ['#FEC22B', '#3CACB1', 'FEC22B'], chart: { foreColor: theme.palette.mode === 'light' ? '#000000' : '#ffffff' } }
    // // console.log("COLUMNNNN: ", chartData);
    return (
        <div id="chart">
            <ReactApexChart options={options} series={chartData.series} type="bar" height={350} />
        </div>
    );
}

export default ColumnChart;